import React from "react";
import { AppUtils } from "../../../utils/app-utils";
import { CreateAccountCard } from "../../../UI-Component-Library";
import { withRouter, Redirect } from "react-router-dom";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { AppConstants } from "../../../utils/app-constants";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
const queryString = require("query-string");

const chipErrorMessage =
  "Sorry, we could not find a record matching that combination of microchip number and name. Please go to register page to register your microchip first.";
const phoneErrorMessage =
  "Sorry, we could not find a record matching that combination of phone number and name.";
const phoneAlreadyExistMsg =
  "There is already an account associated with this Phone number.";

class SignUpStepOne extends React.Component {
  constructor() {
    super();
    this.state = {
      lookupNumber: "",
      firstName: "",
      lastName: "",
      contactConsent: false,
      isFirstNameError: false,
      isLastNameError: false,
      lookUpNumberError: "",
      isLookUpNumberError: false,
      accountNumberType: undefined,
      errorMessage: "",
      isMicrochipValidError: false,
      flow: undefined,
      isFirstPhoneError: false,
      isSecondPhoneError: false,
      isThirdPhoneError: false,
      isPhoneError: false,
      firstPhone: "",
      secondPhone: "",
      thirdPhone: "",
      phoneNumbers: [],
    };
    this.cRef = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const parsed = queryString.parse(this.props.history.location.search);
    if (parsed.type) {
      this.setState(
        {
          accountNumberType: parsed.type,
          flow: parsed.flow,
        },
        () => {}
      );
    }
  }
  handleInputChange = (e) => {
    if (e.target.name === "lookupNumber") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else {
      if (
        AppUtils.isAlphaNumeric(e.target.value) ||
        e.keyCode === 8 ||
        e.target.value === ""
      ) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  getPhoneNumbers = () => {
    return [
      {
        type: "Home",
        number: this.state.firstPhone,
      },
      {
        type: "Mobile",
        number: this.state.secondPhone,
      },
      {
        type: "Work",
        number: this.state.thirdPhone,
      },
    ];
  };

  sendEmail = (emailAddress) => {
    let errorMessage = "";
    this.setState({
      errorMessage,
    });
    const requestData = {
      email: emailAddress,
    };
    axios
      .processPost(
        ApiConstants.URL_POST_FORGOT_PASSWORD_EMAIL,
        this.cRef,
        requestData
      )
      .then((response) => {
        this.setState({
          errorMessage: `An account is already linked to this phone number. Please check your email for a password reset or call ${FeatureFlagUtils.getPhoneNumber()}`,
        });
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
      });
  };

  validateAndCreateAccount = () => {
    let isFirstNameError = false;
    let isLastNameError = false;
    let lookUpNumberError = "";
    let isLookUpNumberError = false;
    let errorMessage = "";
    let isPhoneError = false;
    let isFirstPhoneError = false;
    let isSecondPhoneError = false;
    let isThirdPhoneError = false;

    if (AppUtils.isEmptyString(this.state.firstName)) {
      isFirstNameError = true;
    }
    if (AppUtils.isEmptyString(this.state.lastName)) {
      isLastNameError = true;
    }

    if (AppUtils.isEqualString(this.state.accountNumberType, "phone")) {
      if (
        AppUtils.isEmptyString(this.state.firstPhone) &&
        AppUtils.isEmptyString(this.state.secondPhone) &&
        AppUtils.isEmptyString(this.state.thirdPhone)
      ) {
        isPhoneError = true;
      } else {
        if (
          !AppUtils.isEmptyString(this.state.firstPhone) &&
          !AppUtils.isValidPhoneNumber(this.state.firstPhone)
        ) {
          isFirstPhoneError = true;
        }
        if (
          !AppUtils.isEmptyString(this.state.secondPhone) &&
          !AppUtils.isValidPhoneNumber(this.state.secondPhone)
        ) {
          isSecondPhoneError = true;
        }
        if (
          !AppUtils.isEmptyString(this.state.thirdPhone) &&
          !AppUtils.isValidPhoneNumber(this.state.thirdPhone)
        ) {
          isThirdPhoneError = true;
        }
      }
    } else {
      if (AppUtils.isEmptyString(this.state.lookupNumber)) {
        lookUpNumberError =
          this.state.accountNumberType === "policy"
            ? "Please enter a valid policy number."
            : "Please enter a valid microchip number.";
        isLookUpNumberError = true;
      }
    }

    if (
      !isFirstNameError &&
      !isLastNameError &&
      !isLookUpNumberError &&
      !isPhoneError &&
      !isFirstPhoneError &&
      !isSecondPhoneError &&
      !isThirdPhoneError
    ) {
      const requestData = (({
        firstName,
        lastName,
        accountNumberType,
        contactConsent,
      }) => ({
        firstName,
        lastName,
        accountNumberType,
        contactConsent,
      }))(this.state);

      if (AppUtils.isEqualString(this.state.accountNumberType, "phone")) {
        requestData["HomePhone"] = this.state.firstPhone;
        requestData["CellularPhone"] = this.state.secondPhone;
        requestData["Phone"] = this.state.thirdPhone;
      } else {
        requestData["lookupNumber"] = this.state.lookupNumber;
      }

      const flow = this.state.flow ? this.state.flow : "email";

      axios
        .processPost(
          ApiConstants.URL_POST_SIGNUP_FIRST_STEP,
          this.cRef,
          requestData
        )
        .then((response) => {
          DataLayerUtils.createAccountEvent(
            this.state.accountNumberType,
            `submit ${this.state.accountNumberType} number`,
            flow,
            this.state.lookupNumber
          );

          const data = {
            lookUpNumber: this.state.lookupNumber,
            email: response.data?.email,
          };
          this.props.history.push({
            pathname: "/auth/signup/step2",
            search: `?busRel=${response.data?.busrelaccount}&accType=${this.state.accountNumberType}&flow=${flow}`,
            state: data,
          });
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              errorMessage: errors[0],
            });

            const errorMessageContent = errors[0];
            if (errorMessageContent === chipErrorMessage) {
              DataLayerUtils.createAccountEvent(
                this.state.accountNumberType,
                `submit ${this.state.accountNumberType} number`,
                "organic",
                this.state.lookupNumber
              );
              this.setState({
                isMicrochipValidError: true,
              });
            } else if (errorMessageContent === phoneErrorMessage) {
              DataLayerUtils.createAccountEvent(
                this.state.accountNumberType,
                `submit ${this.state.accountNumberType} number`,
                "organic",
              );
              const phoneNumbers = this.getPhoneNumbers();
              this.setState({
                isMicrochipValidError: true,
                phoneNumbers,
              });
            } else if (errorMessageContent === phoneAlreadyExistMsg) {
              DataLayerUtils.createAccountEvent(
                this.state.accountNumberType,
                `submit ${this.state.accountNumberType} number`,
                "organic",
              );
              const data = err?.response?.data?.data;
              if (data?.hasPassword) {
                this.props.history.push({
                  pathname: "/auth/login",
                  state: {
                    email: data?.email,
                  },
                });
              } else {
                this.sendEmail(data?.email);
              }
            } else {
              DataLayerUtils.formErrorEvent(
                "create an account",
                this.state.accountNumberType,
                errors[0]
              );
            }
          }
        });
    } else {
      if (isFirstNameError && isLastNameError && isPhoneError) {
        errorMessage =
          "Required fields are missing: first name, last name, phone number (home, mobile, or work)";
      }
      this.setState({
        isFirstNameError,
        isLastNameError,
        lookUpNumberError,
        isLookUpNumberError,
        isPhoneError,
        errorMessage,
        isFirstPhoneError,
        isSecondPhoneError,
        isThirdPhoneError,
      });
    }
  };

  handlePhoneInputNumber = (e) => {
    const regex = /^[0-9\-]+$/;
    if (
      e.target.value.match(regex) ||
      e.keyCode === 8 ||
      e.target.value === ""
    ) {
      const number = AppUtils.formatPhoneNumber(
        e.target.value,
        this.state[e.target.name]
      );
      this.setState({
        [e.target.name]: number,
      });
    }
  };

  render() {
    const { isMicrochipValidError } = this.state;
    if (isMicrochipValidError) {
      return (
        <Redirect
          to={{
            pathname: "/register-pet",
            state: {
              type: this.state.accountNumberType,
              microchipNumber: this.state.lookupNumber,
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              phoneNumbers: this.state.phoneNumbers,
            },
          }}
        ></Redirect>
      );
    }
    return (
      <div ref={this.cRef} id="sign-up-top">
        <CreateAccountCard
          phoneNumber={FeatureFlagUtils.getPhoneNumber()}
          onClickToCall={() => DataLayerUtils.clickToCallEvent()}
          errorMessage={this.state.errorMessage}
          type={this.state.accountNumberType?.toLowerCase()}
          policyNumberInputProps={{
            id: "lookup_number_input",
            value: this.state.lookupNumber,
            name: "lookupNumber",
            error: this.state.isLookUpNumberError,
            hint: this.state.lookUpNumberError,
            onChange: this.handleInputChange,
          }}
          firstNameInputProps={{
            id: "FS_first_name_input",
            value: this.state.firstName,
            name: "firstName",
            maxLength: "50",
            error: this.state.isFirstNameError,
            hint: this.state.isFirstNameError
              ? "Please enter a valid first name."
              : "",
            onChange: this.handleInputChange,
          }}
          lastNameInputProps={{
            id: "FS_last_name_input",
            value: this.state.lastName,
            name: "lastName",
            maxLength: "50",
            error: this.state.isLastNameError,
            hint: this.state.isLastNameError
              ? "Please enter a valid last name."
              : "",
            onChange: this.handleInputChange,
          }}
          firstPhoneNumberProps={{
            value: this.state.firstPhone,
            name: "firstPhone",
            maxLength: "12",
            error: this.state.isPhoneError || this.state.isFirstPhoneError,
            hint: this.state.isPhoneError
              ? "At least one valid phone number is required."
              : this.state.isFirstPhoneError
              ? "Please enter a valid phone number."
              : "",
            onChange: (event) => {
              this.handlePhoneInputNumber(event);
            },
          }}
          secondPhoneNumberProps={{
            value: this.state.secondPhone,
            name: "secondPhone",
            maxLength: "12",
            error: this.state.isPhoneError || this.state.isSecondPhoneError,
            hint: this.state.isPhoneError
              ? "At least one valid phone number is required."
              : this.state.isSecondPhoneError
              ? "Please enter a valid phone number."
              : "",
            onChange: (event) => {
              this.handlePhoneInputNumber(event);
            },
          }}
          thirdPhoneNumberProps={{
            value: this.state.thirdPhone,
            name: "thirdPhone",
            maxLength: "12",
            error: this.state.isPhoneError || this.state.isThirdPhoneError,
            hint: this.state.isPhoneError
              ? "At least one valid phone number is required."
              : this.state.isThirdPhoneError
              ? "Please enter a valid phone number."
              : "",
            onChange: (event) => {
              this.handlePhoneInputNumber(event);
            },
          }}
          checkboxProps={{
            id: "FS_consent_checkbox",
            checked: this.state.contactConsent,
            name: "contactConsent",
            onChange: (e) => {
              this.setState((prevState) => {
                return {
                  contactConsent: !prevState.contactConsent,
                };
              });
            },
          }}
          verbiage={
            AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.CreateAccountVerbiage
              ? AppConstants.CONFIG_JSON?.PARTNER_SETTINGS
                  ?.CreateAccountVerbiage
              : ""
          }
          verbiage2={
            "Please Note: If you already have an account with 24petwatch.com, you don’t need to create another one. You can use the same email address and password to login to MyPethealth.com."
          }
          createAccountButtonProps={{
            id: "FS_create_account_button",
            onClick: () => {
              this.validateAndCreateAccount();
            },
          }}
        />
      </div>
    );
  }
}
export default withRouter(SignUpStepOne);
