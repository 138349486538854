/* eslint-disable no-useless-escape */
import moment from "moment";
import { AppConstants } from "./app-constants";
export class AppUtils {
  static logOutCallBack;
  static appRef;
  static EMAIL_REGEX = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  static PASSWORD_REGEX = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&\s]{8,}$/
  );
  static CA_ZIP_REGEX = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
  static US_ZIP_REGEX = new RegExp(/^\d{5}(?:[-\s]\d{4})?$/);
  /*
   123-456-7890
   (123) 456-7890
   123 456 7890
   123.456.7890
   +91 (123) 456-7890
  */
  static PHONE_REGEX = new RegExp(
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  );

  static ONLY_DIGITS_REGEX = new RegExp(/^\d+$/);
  static EXPIRY_REGEX = new RegExp(/^\d{2}\/\d{2}$/);
  static VALID_PHONE_CHRS = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "-",
  ];
  static isValidExpiry(str) {
    return AppUtils.EXPIRY_REGEX.test(str);
  }

  static isOnlyDigits(str) {
    return AppUtils.ONLY_DIGITS_REGEX.test(str);
  }
  static isOnlyDigitsOrEmptyString(str) {
    return AppUtils.isEmptyString(str) || AppUtils.ONLY_DIGITS_REGEX.test(str);
  }
  static isValidPhoneNumber(phone) {
    return AppUtils.PHONE_REGEX.test(phone);
  }
  static isValidZipCode(zipCode) {
    return AppUtils.isUsCountry()
      ? AppUtils.US_ZIP_REGEX.test(zipCode)
      : AppUtils.CA_ZIP_REGEX.test(zipCode);
  }

  static isValidPhoneNumberCharacters(phone_number) {
    const phone_number_chrs = phone_number.split("");
    for (let phoneDigit of phone_number_chrs) {
      if (!AppUtils.VALID_PHONE_CHRS.includes(phoneDigit)) {
        return false;
      }
    }
    return true;
  }

  static saveCallbacks(logOutCallBack, showErrorCallback, appRef) {
    AppUtils.logOutCallBack = logOutCallBack;
    AppUtils.appRef = appRef;
    AppUtils.showErrorCallback = showErrorCallback;
  }
  static showGenericError() {
    AppUtils.showErrorCallback && AppUtils.showErrorCallback(AppUtils.appRef);
  }
  static logUserOutUsingCallback() {
    AppUtils.logOutCallBack && AppUtils.logOutCallBack(AppUtils.appRef);
  }
  static isValidPassword(password) {
    return AppUtils.PASSWORD_REGEX.test(password);
  }
  static isValidEmail(inputEmail) {
    return AppUtils.EMAIL_REGEX.test(inputEmail);
  }
  static isAuthenticated() {
    const token = localStorage.getItem("authToken");
    const bool = token && token !== "";
    return bool;
  }

  static clearLoginData() {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
  }

  static login(user) {
    localStorage.setItem("authToken", user.userRelationId);
    localStorage.setItem("user", JSON.stringify(user));
  }

  static changeEmailAddress(email) {
    const user = AppUtils.getLoggedInUser();
    if (user) {
      user.email = email;
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  static getLoggedInUser() {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : {};
  }

  static getBusRelAccount() {
    return AppUtils.getLoggedInUser().busRelAccount;
  }
  static getUserRelationId() {
    return AppUtils.getLoggedInUser().userRelationId;
  }
  static getUserEmail() {
    return AppUtils.getLoggedInUser().email;
  }
  static getCountryCode() {
    return AppUtils.getLoggedInUser().country;
  }
  static getCurrency() {
    return AppUtils.isUsCountry()
      ? AppConstants.CONFIG_JSON.CURRENCY_CODES.us
      : AppConstants.CONFIG_JSON.CURRENCY_CODES.ca;
  }
  static getLoginType() {
    return AppUtils.getLoggedInUser().loginType;
  }
  static getToken() {
    return localStorage.getItem("authToken");
  }
  static isUrl(inputString) {
    return inputString && inputString.indexOf("http") > -1;
  }
  static getCHFormattedDate(date) {
    return moment(date).format(AppConstants.CH_DATE_FORMAT);
  }
  static getDateWithoutSeconds(dateStr) {
    const dateStr1 = dateStr.replace("T00:00:00", "");
    return dateStr1;
  }
  static getYYMMDDFormattedDate(date) {
    if (!date) {
      return "";
    }
    return moment(date, "MM-DD-YYYY").format("YYYY-MM-DD");
  }
  static getPDFormattedDate(date) {
    if (!date) {
      return "";
    }
    const splitted = date.split("-");
    if (splitted && splitted.length > 0) {
      return (
        moment(splitted[0].trim(), "MM-DD-YYYY").format(
          AppConstants.PD_DATE_FORMAT
        ) +
        " - " +
        moment(splitted[1].trim(), "MM-DD-YYYY").format(
          AppConstants.PD_DATE_FORMAT
        )
      );
    }
    return "";
  }
  static uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  static isEmptyString(input) {
    return !input || input.trim() === "";
  }
  static downloadDocument(url) {
    const x = document.createElement("A");
    x.setAttribute("style", "display:none;");
    document.body.appendChild(x);
    x.href = url;
    x.target = "_blank";
    x.download = "filename.pdf";
    x.click();
  }
  static openLinkInNewTab(url) {
    const x = document.createElement("A");
    x.setAttribute("style", "display:none;");
    document.body.appendChild(x);
    x.href = url;
    x.target = "_blank";
    x.click();
  }
  static focusOnDiv(divId) {
    const el = document.getElementById(divId);
    if (el) {
      el.scrollIntoView();
    }
  }
  static keepFocusingOnDiv(divId) {
    const el = document.getElementById(divId);
    if (el) {
      const interval = setInterval(() => {
        el.scrollIntoView();
      }, 100);
      setTimeout(() => {
        clearInterval(interval);
      }, 3000);
    }
  }
  static getConvertedPrice(price) {
    return price || price === 0
      ? AppConstants.CURRENCY_FORMAT + parseFloat(price).toFixed(2)
      : "";
  }
  static getCoverageAmount(policy) {
    var price = policy?.coverageAmount;
      if (price && price == 1000000) {
        return "Unlimited";      
    } else {
        return AppUtils.getConvertedPrice(price);
    }
  }
  static getExtractedPrice(price) {
    return price && price.length > 1
      ? parseFloat(price.replace(AppConstants.CURRENCY_FORMAT, ""))
      : 0;
  }
  static getCartId() {
    const cartDataJson = localStorage.getItem("cartData");
    if (cartDataJson) {
      const cartData = JSON.parse(cartDataJson);
      return cartData.cartId;
    }
    return "";
  }

  static isAlphaNumeric(value) {
    // Alphanumeric which starts with alphabet.
    const regex = /^[a-zA-Z][0-9a-zA-Z .'-]*$/;
    return value.match(regex);
  }

  static birthMonths = [
    {
      value: "",
      label: "Month",
    },
    {
      value: 1,
      label: "1 - January",
    },
    {
      value: 2,
      label: "2 - February",
    },
    {
      value: 3,
      label: "3 - March",
    },
    {
      value: 4,
      label: "4 - April",
    },
    {
      value: 5,
      label: "5 - May",
    },
    {
      value: 6,
      label: "6 - June",
    },
    {
      value: 7,
      label: "7 - July",
    },
    {
      value: 8,
      label: "8 - August",
    },
    {
      value: 9,
      label: "9 - September",
    },
    {
      value: 10,
      label: "10 - October",
    },
    {
      value: 11,
      label: "11 - November",
    },
    {
      value: 12,
      label: "12 - December",
    },
  ];

  static getBirthYears() {
    let birthYearsOptions = [
      {
        value: "",
        label: "Year",
      },
    ];

    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i >= 1995; i--) {
      birthYearsOptions.push({
        value: i,
        label: `${i}`,
      });
    }
    return birthYearsOptions;
  }
  static isMerckProgram() {
    return AppConstants.CONFIG_JSON?.Program?.toLowerCase() === "merck";
  }
  static isPethealthProgram() {
    return AppConstants.CONFIG_JSON?.Program?.toLowerCase() === "pethealth";
  }
  static isLostPetEnabled() {
    return AppConstants.CONFIG_JSON?.Features?.LostPet?.toLowerCase() === "on";
  }
  static isMicrochipEnabled() {
    return (
      AppConstants.CONFIG_JSON?.Features?.Microchip?.toLowerCase() === "on"
    );
  }
  static isMembershipEnabled() {
    return (
      AppConstants.CONFIG_JSON?.Features?.Membership?.toLowerCase() === "on"
    );
  }
  static isInsuranceEnabled() {
    return (
      AppConstants.CONFIG_JSON?.Features?.Insurance?.toLowerCase() === "on"
    );
  }
  static isEqualString(str1, str2) {
    if (!str1 || !str2) {
      return false;
    }
    return str1?.toLowerCase() === str2?.toLowerCase();
  }
  static copyObject(object) {
    return JSON.parse(JSON.stringify(object));
  }
  static getAlreadyPurchasedAnnualProductName(itemId) {
    const name =
      AppConstants.CONFIG_JSON.AlreadyPurchasedAnnualProductNames[itemId];
    return AppUtils.isEmptyString(name) ? "Unknown Item" : name;
  }
  static getAlreadyPurchasedLifeTimeProductName(itemId) {
    const name =
      AppConstants.CONFIG_JSON.AlreadyPurchasedLifetimeProductNames[itemId];
    return AppUtils.isEmptyString(name) ? "Unknown Item" : name;
  }
  static getAlreadyPurchasedLifeTimePlusProductName(itemId) {
    const name =
      AppConstants.CONFIG_JSON.AlreadyPurchasedLifetimePlusProductNames[itemId];
    return AppUtils.isEmptyString(name) ? "Unknown Item" : name;
  }
  static isLpmTagOrder(itemId) {
    if (itemId !== "LT Warrantt Tag-LG") {
      if (
        itemId.toLowerCase().indexOf("lg") !== -1 ||
        itemId.toLowerCase().indexOf("sm") !== -1
      ) {
        return true;
      }
    }
    return false;
  }
  static isBytetagOrder(itemId) {
    if ( itemId.toLowerCase().indexOf("bytetag") !== -1 ) {
      return true;
    }
    return false;
  }
  static toLowerCase(item) {
    return item ? item.toLowerCase() : "";
  }
  static removePhoneFormatting(item){
    return item ? item.replace(/\D/g,'') : "";
  }
  static isTypeNumber(item){
    var number = /^[-0-9]+$/;
    if(item.match(number)){
      return true;
    }

    return false;
  }
  static isLpmProduct(item) {
    return AppUtils.isEqualString(item.itemType, "LPMProduct");
  }
  static isLpmPlusProduct(item) {
    return AppUtils.isEqualString(item.itemType,  "LPMPlusProduct")
  }
  static isLpmBundleProduct(item) {
    return AppUtils.isEqualString(item.itemType, "LPMBundleProduct");
  }
  static isLpmPlusBundleProduct(item) {
    return AppUtils.isEqualString(item.itemType, "LPMPLUSBundleProduct");
  }
  static isWinbackProduct(item) {
    return AppUtils.isEqualString(item.itemType, "WINBACK Product");
  }
  static isWinbackBundleProduct(item) {
    return AppUtils.isEqualString(item.itemType, "WINBACKBundleProduct");
  }
  static isTagProduct(item) {
    return AppUtils.isEqualString(item.itemType, "TagProduct");
  }
  static getShapeAndStyle(tag) {
    if (this.isTagProduct(tag)) {
      const tagDetails = tag.productName.split(" ");
      const shapeAvailable = ['bone', 'heart', 'circle'];
      let shape = null,
          style = '';
      for (let i = 0; i < tagDetails.length; i += 1){
        if (shapeAvailable.includes(tagDetails[i].toLowerCase())) {
          shape = tagDetails[i].toLowerCase();
          break;
        }
        if (style !== '') style += ' ';
        style += tagDetails[i].toLowerCase();
      }
      return {
        shape,
        style
      }
    }
    return null;
  }
  static getByteTagProductDetails(itemName) {
    let variant, shape, size = null;
    const tag = itemName.toLowerCase();

    variant = tag.indexOf("black") !== -1 ? 'black' : 'white';

    if (tag.indexOf("slide") !== -1) {
      shape = 'slide';
      size = tag.indexOf("lg") !== -1 ? 'large' : 'small/medium';
    } else {
      const pet = tag.indexOf("cat") !== -1 ? 'cat' : 'dog';
      shape = `round ${pet}`;
    }

    return {
      variant,
      shape,
      size
    }
  }
  static isAnnualProduct(item) {
    return AppUtils.isEqualString(item.itemType, "AnnualProduct");
  }
  static isAnnualAdditionalProduct(item) {
    return AppUtils.isEqualString(item.itemType, "AnnualAddProduct");
  }
  static isVetHelplineProduct(item) {
    return (
      AppUtils.isEqualString(item.itemType, "AnnualAddProduct") &&
      AppUtils.isEqualString(item.itemId, "WD Annual Membership")
    );
  }
  static isPetMedAlertAnnualProduct(item) {
    return (
      AppUtils.isAnnualAdditionalProduct(item) &&
      (AppUtils.isEqualString(item.itemName, "24PetMedAlert") ||
        AppUtils.isEqualString(item.productName, "24PetMedAlert"))
    );
  }
  static isPetMedAlertLpmProduct(item) {
    return (
      AppUtils.isLpmBundleProduct(item) &&
      (AppUtils.isEqualString(item.itemName, "24PetMedAlert") ||
        AppUtils.isEqualString(item.productName, "24PetMedAlert"))
    );
  }
  static getProductNameFromItemId(itemId, itemName = '') {
    if ( itemId.toLowerCase().indexOf("bytetag") !== -1  ) {
      return itemName;
    }
    if (itemId !== "LT Warrantt Tag-LG") {
      if (
        itemId.toLowerCase().indexOf("lg") !== -1 ||
        itemId.toLowerCase().indexOf("sm") !== -1
      ) {
        const name = AppConstants.CONFIG_JSON.LpmTags[itemId];
        return AppUtils.isEmptyString(name) ? "Unknown Tag" : name;
        // return "Lifetime metal tags";
      } else if (itemId.toLowerCase().indexOf("tag") !== -1) {
        const name = AppConstants.CONFIG_JSON.StandardTags[itemId];
        return AppUtils.isEmptyString(name) ? "Unknown Tag" : name;
        // return "Standard Metal Tags";
      }
    }

    if(itemId === 'Renewal Win-Back-US' || itemId === 'Renewal Win-Back-CA'){
      return AppConstants.CONFIG_JSON.WinbackOfferItemIds[itemId] || "Unknown Item";
    }
    let name = AppConstants.CONFIG_JSON.AnnualMembershipItemIds[itemId];
    if (AppUtils.isEmptyString(name)) {
      if (AppUtils.isEqualString(AppUtils.getCountryCode(), "us")) {
        name = AppConstants.CONFIG_JSON.USLifetimeMembershipItemIds[itemId];
      } else {
        name = AppConstants.CONFIG_JSON.CALifetimeMembershipItemIds[itemId];
      }
    }

    if (AppUtils.isEmptyString(name)) {
      if (AppUtils.isEqualString(AppUtils.getCountryCode(), "us")) {
        name = AppConstants.CONFIG_JSON.USLifetimePlusMembershipItemIds[itemId];
      } else {
        name = AppConstants.CONFIG_JSON.CALifetimePlusMembershipItemIds[itemId];
      }
    }
    return AppUtils.isEmptyString(name) ? "Unknown Item" : name;
  }
  static getProductFrequency(frequency){
    if(!this.isEmptyString(frequency)){
      return frequency.toLowerCase();
    }

    return 'year';
  }
  static isUsCountry() {
    return AppUtils.getCountryCode().toLowerCase() === "us";
  }
  static saveToFile(response) {
    if (response) {
      const contentDisposition = response.headers["content-disposition"];
      const fileName = AppUtils.getFileNameFromContentDisposition(
        contentDisposition
      );
      AppUtils.saveFile(fileName, response.data);
    }
  }
  static getFileNameFromContentDisposition(disposition) {
    let fileName = "";
    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }
    return fileName;
  }
  static saveFile(fileName, data) {
    // Downloading the file depends on the browser
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(data, fileName); // For EDGE Browser
    } else {
      const a = document.createElement("a");
      a.setAttribute("style", "display:none;");
      document.body.appendChild(a);
      const url = URL.createObjectURL(data);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }
  static triggerAdobeTargetView(viewName) {
    if (
      typeof adobe != "undefined" &&
      adobe.target &&
      typeof adobe.target.triggerView === "function"
    ) {
      adobe.target.triggerView(viewName);
    }
  }
  static triggerAdobeTrackEvent() {
    adobe.target.trackEvent({
      "mbox": "successPageView",
    });
  }
  static formatPhoneNumber(number, prevValue) {
    if (number.length > 3 && number[3] !== '-') {
      number = number.substr(0, 3) + "-" + number.substr(3, number.length);
    }

    if (number.length > 7 && number[7]!== '-') {
      number = number.substr(0, 7) + "-" + number.substr(7, number.length);
    }
    return number;
  }
  static petInfoOptions = [
    {
      label: "Select one...",
      value: "0"
    },
    {
      label: "I picked up my pet from the shelter/finder",
      value: "4"
    },
    {
      label: "I found my pet myself",
      value: "2"
    },
    {
      label: "I surrendered my pet",
      value: "11"
    },
    {
      label: "My pet was never lost",
      value: "1"
    },
    {
      label: "I do not own this pet",
      value: "18"
    },
    {
      label: "This pet is deceased",
      value: "3"
    }
  ];
}
