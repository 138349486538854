import React from "react";
import { PageContent, RmReviewCard, Steps } from "../../../UI-Component-Library";
import { AppUtils } from "../../../utils/app-utils";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
class PetReview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getDisplayValue(value) {
    if (value) {
      const vv = `${value}`;
      if(vv.length > 0) {
        return value;
      }
    }

    return "N/A";
  }

  getMonthValue() {
    let month = `${this.props.formValues.birthMonth}`;
    if (month && month.length === 1) {
      const newMonth = `0${month}`;
      return newMonth;
    }
    return month;
  }

  render() {
    const isPhone = AppUtils.isEqualString(this.props.type, 'phone');
    return (
      <PageContent>
        <Steps
          showSteps={true}
          steps={[
            {
              label: "Pet Info",
              active: false,
              completed: true,
            },
            {
              label: "Review",
              active: true,
            },
          ]}
        />
        <RmReviewCard
          yourInformation={[]}
          petInformation={[
            {
              key: "Microchip Number",
              value: this.getDisplayValue(
                this.props.formValues.microChipNumber
              ),
              notShowMe: isPhone,
            },
            {
              key: "Microchip Provider",
              value: this.getDisplayValue(
                this.props.formValues.microchipProviderName
              ),
              notShowMe: isPhone,
            },
            {
              key: "Pet Name",
              value: this.getDisplayValue(this.props.formValues.petName),
            },
            {
              key: "Species",
              value: this.getDisplayValue(this.props.formValues.petType),
            },
            {
              key: "Gender",
              value: this.getDisplayValue(this.props.formValues.petGender),
            },
            {
              key: "Breed Type",
              value: this.getDisplayValue(this.props.formValues.breedType),
            },
            {
              key: "Breed Name",
              value: this.getDisplayValue(this.props.formValues.petBreedLabel),
            },
            {
              key: "Color",
              value: this.getDisplayValue(this.props.formValues.colorLabel),
            },
            {
              key: "Spayed or Neutered",
              value: this.getDisplayValue(this.props.formValues.isSprayed),
            },
            {
              key: "Birth Month and Year",
              value: `${this.getDisplayValue(
                this.getMonthValue()
              )}/${this.getDisplayValue(this.props.formValues.birthYear)}`,
            },
            {
              key: "Clinic Where Pet Was Microchipped",
              value: this.getDisplayValue(
                this.props.formValues.clinicInfoLabel
              ),
              notShowMe: isPhone,
            },
            {
              key: "Consent to Release Contact Info When Pet Is Found",
              value: this.getDisplayValue(this.props.formValues.isConsent),
            },
          ]}
          petInfoEditLinkProps={{
            id:"petEditId",
            onClick: (event) => {
              DataLayerUtils.registerNewPetEvent(
                AppUtils.getUserRelationId(),
                this.props.formValues.microChipNumber,
                "edit pet info"
              )
              this.props.onEditButtonClicked(1);
            },
          }}
          errorMessage={this.props.formValues.reviewPageError}
          confirmAndSubmitButtonProps={{
            id: "petSubmitId",
            onClick: (event) => {
              DataLayerUtils.registerNewPetEvent(
                AppUtils.getUserRelationId(),
                this.props.formValues.microChipNumber,
                "confirm and submit"
              )
              this.props.clickOnSubmit();
            },
          }}
        />
      </PageContent>
    );
  }
}

export default PetReview;
