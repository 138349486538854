import { AppUtils } from "../../../utils/app-utils";
import React from "react";
import { Banner } from "../../../UI-Component-Library";
import { OptionCheckbox } from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { ValidationMessages } from "../../../utils/validation-messages";
export class MembershipPurchaseUtils {
  static getDefaultData() {
    return {
      availableMembership: undefined,
      annualMembership: undefined,
      lpmMembership: undefined,
      lpmPlusMembership: undefined,
      selectedPlan: "",
      selectedPetTagOption: "none",
      tagStyle: undefined,
      tagShape: undefined,
      selectedSize: "Large",
      petMedAlertProduct: undefined,
      vetHelplineProduct: undefined,
      isPetMedAlertSelected: false,
      isVetHelplineSelected: false,
      showSizeSelect: true,
      showColorSelect: false,
      memberType: undefined,
    };
  }
  static getAnimalName(id, customerAnimals) {
    const pet = customerAnimals.find((animal) => animal.animalId === id);
    return pet ? pet.animalName : "";
  }
  static getPetIndexAndItemIndex(petShoppingCart, petId, itemId) {
    const keys = Object.keys(petShoppingCart);
    const petIndex = keys.indexOf(petId);
    const items = petShoppingCart[petId]?.items;
    if (items) {
      for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
        if (AppUtils.isEqualString(items[itemIndex].itemId, itemId)) {
          return {
            petIndex,
            itemIndex,
          };
        }
      }
    }
  }
  static getPetIndexAndItemType(petShoppingCart, petId, itemType, option = "") {
    const keys = Object.keys(petShoppingCart);
    const petIndex = keys.indexOf(petId);
    const items = petShoppingCart[petId]?.items;
    if (items) {
      if(option){
        for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
          if (
            AppUtils.isEqualString(items[itemIndex].itemType, itemType) &&
            items[itemIndex].productName.toLowerCase().includes(option)
          ) {
            return {
              petIndex,
              itemIndex,
            };
          }
        }
      } else {
        for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
          if (AppUtils.isEqualString(items[itemIndex].itemType, itemType)) {
            return {
              petIndex,
              itemIndex,
            };
          }
        }
      }
    }
  }
  static getPriceForExistingOrder(itemId, availableData) {
    if (availableData.lpmMembership) {
      if (AppUtils.isEqualString(itemId, availableData.lpmMembership.itemId)) {
        return AppUtils.getConvertedPrice(
          availableData.lpmMembership.itemPrice
        );
      }
      for (let bundle of availableData.lpmMembership.bundleList) {
        if (AppUtils.isEqualString(itemId, bundle.itemId)) {
          return AppUtils.getConvertedPrice(bundle.itemPrice);
        }
      }
    }

    if (availableData.lpmPlusMembership) {
      if (
        AppUtils.isEqualString(itemId, availableData.lpmPlusMembership.itemId)
      ) {
        return AppUtils.getConvertedPrice(
          availableData.lpmPlusMembership.itemPrice
        );
      }
      for (let bundle of availableData.lpmPlusMembership.bundleList) {
        if (AppUtils.isEqualString(itemId, bundle.itemId)) {
          return AppUtils.getConvertedPrice(bundle.itemPrice);
        }
      }
    }

    if (availableData.annualMembership) {
      if (
        AppUtils.isEqualString(itemId, availableData.annualMembership.itemId)
      ) {
        return AppUtils.getConvertedPrice(
          availableData.annualMembership.salesPrice
        );
      }
    }
    if (availableData.standardTagList) {
      for (let tag of availableData.standardTagList) {
        if (AppUtils.isEqualString(itemId, tag.itemId)) {
          return AppUtils.getConvertedPrice(tag.price);
        }
      }
    }

    if (availableData.lifetimeTagList) {
      for (let tag of availableData.lifetimeTagList) {
        if (AppUtils.isEqualString(itemId, tag.itemId)) {
          return AppUtils.getConvertedPrice(tag.price);
        }
      }
    }

    // Adding for byte tag

    if (availableData.byteTagList) {
      for (let tag of availableData.byteTagList) {
        if (AppUtils.isEqualString(itemId, tag.itemId)) {
          return AppUtils.getConvertedPrice(tag.price);
        }
      }
    }

    if (availableData.petMedAlertProduct) {
      if (
        AppUtils.isEqualString(itemId, availableData.petMedAlertProduct.itemId)
      ) {
        return AppUtils.getConvertedPrice(
          availableData.petMedAlertProduct.price
        );
      }
    }
    if (availableData.vetHelplineProduct) {
      if (
        AppUtils.isEqualString(itemId, availableData.vetHelplineProduct.itemId)
      ) {
        return AppUtils.getConvertedPrice(
          availableData.vetHelplineProduct.price
        );
      }
    }

    if (availableData.winBackBundle) {
      if (AppUtils.isEqualString(itemId, availableData.winBackBundle.itemId)) {
        return AppUtils.getConvertedPrice(
          availableData.winBackBundle.itemPrice
        );
      }
      for (let bundle of availableData.winBackBundle.bundleList) {
        if (AppUtils.isEqualString(itemId, bundle.itemId)) {
          return AppUtils.getConvertedPrice(bundle.itemPrice);
        }
      }
    }
    return "";
  }
  static getFilteredOrdersForLifetimeProduct(
    orders,
    lpmMembership,
    lpmPlusMembership,
    winbackBundle
  ) {
    let filtered = orders;
    let isLpmOrder;
    let isLpmPlusOrder;
    let isWinbackOrder;
    for (let order of orders) {
      if (
        AppUtils.isEqualString(order.itemType, "LPMPlusProduct") &&
        lpmPlusMembership
      ) {
        isLpmPlusOrder = true;
        order.bundleList = lpmPlusMembership.bundleList;
      }

      if (
        AppUtils.isEqualString(order.itemType, "LPMProduct") &&
        lpmMembership
      ) {
        isLpmOrder = true;
        order.bundleList = lpmMembership.bundleList;
      }

      if (
        AppUtils.isEqualString(order.itemType, "WINBACK Product") &&
        winbackBundle
      ) {
        isWinbackOrder = true;
        order.bundleList = winbackBundle.bundleList;
      }
    }

    if (isLpmPlusOrder) {
      filtered = orders.filter(
        (order) => !MembershipPurchaseUtils.isLpmPlusBundleItem(order)
      );
    }
    if (isLpmOrder) {
      filtered = orders.filter(
        (order) => !MembershipPurchaseUtils.isLpmBundleItem(order)
      );
    }

    if (isWinbackOrder) {
      filtered = orders.filter(
        (order) => !MembershipPurchaseUtils.isWinbackBundleItem(order)
      );
    }

    return filtered;
  }

  static getFilteredBundleList(bundleList) {
    return bundleList;

    // return bundleList.filter((bundle) => {
    //   return AppUtils.isUsCountry()
    //     ? !AppUtils.isEqualString(bundle.itemId, "Maint Fee Lifetime")
    //     : !AppUtils.isEqualString(bundle.itemId, "PLH_000013");
    // });
  }
  static isLpmBundleItem(order) {
    return AppUtils.isEqualString(order.itemType, "LPMBundleProduct");
  }
  static isLpmPlusBundleItem(order) {
    return AppUtils.isEqualString(order.itemType, "LPMPLUSBundleProduct");
  }
  static isWinbackBundleItem(item) {
    return AppUtils.isEqualString(item.itemType, "WINBACKBundleProduct");
  }
  static saveCartDataInLocalStorage(cartData) {
    localStorage.setItem("cartData", JSON.stringify(cartData));
  }
  static getCartDataFromLocalStorage() {
    const data = localStorage.getItem("cartData");
    return data ? JSON.parse(data) : {};
  }
  static removeCartDataFromLocalStorage() {
    localStorage.removeItem("cartData");
    localStorage.removeItem(AppUtils.getUserRelationId());
    localStorage.removeItem("CartBeforeRefresh");
  }
  static offerBanner() {
    return (
      <Banner
        title="Offer - Get both subscriptions of 24PetMedAlert and 24/7 Vet Helpline for a total price of just $19.95/year!"
        color={Banner.Colors.secondary}
      />
    );
  }
  static anotherBanner() {
    return (
      <Banner
        title="Good news, you chose a bundle that gives you the best total price of $19.95/year for both 24PetMedAlert and 24/7 Vet Helpline."
        color={Banner.Colors.secondary}
      />
    );
  }

  static noBanner = () => {
    return <div></div>;
  };
  static errorBanner = (errorMessage) => {
    return <Banner title={errorMessage} />;
  };
  static subscriptionUpdatedBanner = () => {
    return (
      <Banner title="Subscription updated!" color={Banner.Colors.success} />
    );
  };
  static warningBanner = () => {
    return (
      <Banner
        color={Banner.Colors.warning}
        title={"We need to do this over the phone"}
        subtitle={
          "Please call 1-866-597-2424 to add this feature to your account"
        }
      />
    );
  };
  static getVariant(element, isInActive) {
    if (element.recId === "0") {
      return OptionCheckbox.Variants.disabled;
    }
    if (isInActive || !element.autoRenew) {
      return OptionCheckbox.Variants.inactive;
    }
    return element.expiredProduct
      ? OptionCheckbox.Variants.error
      : OptionCheckbox.Variants.active;
  }
  static getCardErrorMessage(cardNumberError, cardNotExpectedError) {
    if (cardNumberError) {
      return ValidationMessages.INVALID_CARD_NUMBER;
    }
    if (AppUtils.isUsCountry()) {
      return "Only Visa, MasterCard & Discover credit cards accepted";
    }
    return "Only Visa & MasterCard credit cards accepted ";
  }
  static removeItemFromDatabase(basketLineId) {
    axios
      .processGet(ApiConstants.URL_REMOVE_ITEM + basketLineId)
      .then((response) => {});
  }
  static isValidNewCard(cardNumber) {
    let isValid = false;
    const cardType = MembershipPurchaseUtils.getCardType(cardNumber);
    if (AppUtils.isUsCountry()) {
      switch (cardType) {
        case "Mastercard":
          isValid = true;
          break;
        case "Visa":
          isValid = true;
          break;
        case "Discover":
          isValid = true;
          break;
        default:
          isValid = false;
      }
    } else {
      switch (cardType) {
        case "Mastercard":
          isValid = true;
          break;
        case "Visa":
          isValid = true;
          break;
        default:
          isValid = false;
      }
    }
    return isValid;
  }
  static getCardType(number) {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null) return "Visa";

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    )
      return "Mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null) return "AMEX";

    // Discover
    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) != null) return "Discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null) return "Diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null) return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null) return "JCB";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null) return "Visa Electron";

    return "";
  }
  static isLPMPlusProductSelected(productName) {
    return (
      AppUtils.isEqualString(productName, "LPM-PLUS") ||
      AppUtils.isEqualString(productName, "LPM-PLUS-CA")
    );
  }
}
