import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Icon, Text } from '../../'
import { Hint } from '../Input/InputComponents'

export class Select extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      // track input focused state to apply an appropriate class to label
      focused: false
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  static propTypes = {
    hidden: PropTypes.bool,
    error: PropTypes.bool,
    helper: PropTypes.any,
    hint: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    label: PropTypes.any,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    ),
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    options: [],
    disabled: false,
    error: false,
    hidden: false
  }

  handleFocus = (e) => {
    this.setState({ focused: true })
    this.props.onFocus?.(e)
  }

  handleBlur = (e) => {
    this.setState({ focused: false })
    this.props.onBlur?.(e)
  }

  render() {
    const { focused } = this.state

    const {
      hidden,
      error,
      helper,
      hint,
      label,
      value,
      options,
      id,
      className,
      style,
      refProp,
      disabled,
      onChange
    } = this.props

    if (hidden) return null

    const componentClasses = getClasses(focused, error, disabled)

    return (
      <div
        className={cn(componentClasses.container, className)}
        style={style}
        ref={refProp}
      >
        {label && (
          <label className={cn(componentClasses.label)} htmlFor={id}>
            {label}
          </label>
        )}

        {helper && (
          <Text
            className={cn(componentClasses.helper)}
            type={Text.Types.caption}
          >
            {helper}
          </Text>
        )}

        <div className={cn(componentClasses.selectWrapper)}>
          <select
            disabled={disabled}
            id={id}
            value={value}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            className={cn(componentClasses.select)}
          >
            {options.map(({ value, label }) => (
              <option key={`${value}-${label}`} id={`option-${value}`} value={value}>
                {label}
              </option>
            ))}
          </select>
          <Icon type='arrow_drop_down' />
        </div>

        {hint && <Hint hint={hint} className={cn(componentClasses.hint)} />}
      </div>
    )
  }
}

const getClasses = (focused, error, disabled) => {
  const classes = {
    container: ['ui-select__container'],
    label: ['ui-select__label'],
    helper: ['ui-select__helper'],
    selectWrapper: ['ui-select--wrapper'],
    select: ['ui-select'],
    hint: ['ui-select__hint']
  }

  focused && classes.container.push('ui-select__container--focused')
  disabled && classes.container.push('ui-select__container--disabled')
  error && !disabled && classes.container.push('ui-select__container--error')
  error && !disabled && classes.select.push('ui-select--error')

  return classes
}
