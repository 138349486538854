import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Link } from '../Link'
import { Icon } from '../Icon'

export class BreadCrumb extends React.PureComponent {
  static propTypes = {
    breadCrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        onClick: PropTypes.func,
        active: PropTypes.bool,
        className: PropTypes.string,
      })
    ),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    breadCrumbs: []
  }

  render() {
    const { id, className, style, refProp, breadCrumbs } = this.props

    return (
      <div
        id={id}
        className={cn('ui-bread-crumb', className)}
        style={style}
        ref={refProp}
      >
        {breadCrumbs.map((item, index) => {
          return (
            <div className='ui-bread-crumb-link' key={`breadCrumb${index}`}>
              <Link
                href='#'
                disabled={item.active}
                onClick={item.onClick}
                className={item?.className}
              >
                {item.name}
              </Link>
              {!item.active ? <Icon type="chevron_right"></Icon> : '' }
            </div>
          )
        })}
      </div>
    )
  }
}
