import React from 'react'
import { PageContent, RmYourInfoCard, Steps, Button } from "../../../UI-Component-Library";
import { AppConstants } from "../../../utils/app-constants";
import {AppUtils} from "../../../utils/app-utils";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
class RegistrationStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitClicked: false,
      emergencyContactFields: this.props.formValues.emergencyContacts,
      phoneNumberFields: this.props.formValues.phoneNumbers,
    };
  }

  getStatesOrProvinces() {
    if (this.props.formValues.country?.toLowerCase() === "us") {
      return AppConstants.STATES;
    } else if (this.props.formValues.country?.toLowerCase() === "ca") {
      return AppConstants.PROVINCES;
    }
  }

  getButtonState = () => {
    this.isFirstNameRequired =
      this.props.formValues.firstName.length > 0 ? false : true;
    this.isLastNameRequired =
      this.props.formValues.lastName.length > 0 ? false : true;
    this.isCountryRequired =
      this.props.formValues.country.length > 0 ? false : true;
    this.isZipCodeRequired =
      this.props.formValues.zipCode.length > 0 ? false : true;
    this.isEmailRequired =
      this.props.formValues.email.length > 0 ? false : true;
    this.isCityRequired = this.props.formValues.city.length > 0 ? false : true;
    this.isStreetRequired =
      this.props.formValues.street.length > 0 ? false : true;
    this.isStateRequired =
      this.props.formValues.state.length > 0 ? false : true;
    if (
      !this.isFirstNameRequired &&
      !this.isLastNameRequired &&
      !this.isCountryRequired &&
      !this.isZipCodeRequired &&
      !this.isEmailRequired &&
      !this.isCityRequired &&
      !this.isStateRequired &&
      !this.isStreetRequired &&
      AppUtils.isValidEmail(this.props.formValues.email)
    ) {
      return false;
    }
    return true;
  };

  isBusinessPhoneError () {
    if (this.props.formValues.businessPhone.length > 0) {
      if (AppUtils.isValidPhoneNumber(this.props.formValues.businessPhone)) {
        return false
      } else {
        return true
      }
    }
    return false;
  }
  getPrimaryPhoneValidationStatus() {
    if (this.isPrimaryPhoneRequired && this.state.isSubmitClicked) {
      return {
        showError: true,
        errorMessage: "Please fill out your primary phone number before continuing.",
      };
    }

    if (
      this.props.formValues.primaryPhone.length > 0 &&
      !AppUtils.isValidPhoneNumber(this.props.formValues.primaryPhone)
    ) {
      return {
        showError: true,
        errorMessage: "Please enter valid phone number",
      };
    }

    return {
      showError: false,
      errorMessage: "",
    };
  }


  removeEmergencyContact(index){
    let emergencyContactFields = this.state.emergencyContactFields;
    emergencyContactFields.splice(index, 1);
    this.setState({
      emergencyContactFields,
    },
    () => {
      this.props.updateEmergencyContacts(this.state.emergencyContactFields);
    })
  }

  removePhoneNumber(index){
    let phoneNumberFields = this.state.phoneNumberFields;
    let length = this.state.phoneNumberFields.length;
    phoneNumberFields.splice(index, 1);
    if(index !== length-1) {
      phoneNumberFields[index].selectPhoneFieldProps["options"] = this.getPhoneTypeOptions(index);
      phoneNumberFields[index].selectPhoneFieldProps.onChange = (event) => {
        this.changePhoneInputHandler(event, index)
      }
      phoneNumberFields[index].inputPhoneFieldProps.onChange = (event) => {
        this.changePhoneInputHandler(event, index)
      }
    }
    if (index === length-1 && index !== 0){
      phoneNumberFields[index - 1].selectPhoneFieldProps["disabled"] = false;
    }
    this.setState({
      phoneNumberFields,
    },
    () => {
      this.props.updatePhoneNumbers(this.state.phoneNumberFields);
    })
  }

  changePhoneInputHandler = (event, index) =>{
    let updatedPhoneNumberFields = this.state.phoneNumberFields;
    if (event.target.name === "inputPhoneFieldProps") {
      const regex = /^[0-9\-]+$/;
      const prevValue = updatedPhoneNumberFields[index][event.target.name].value;
      if (event.target.value.match(regex) || event.keyCode === 8 || event.target.value === "") {
        const number = AppUtils.formatPhoneNumber(event.target.value, prevValue);
        updatedPhoneNumberFields[index][event.target.name].value = number;
      }
    } else {
      updatedPhoneNumberFields[index]["selectPhoneFieldProps"].value = event.target.value;
      updatedPhoneNumberFields[index].inputPhoneFieldProps["hint"] = "";
      updatedPhoneNumberFields[index].inputPhoneFieldProps["error"] = false;
      if(event.target.value === "Home") {
        updatedPhoneNumberFields[index].inputPhoneFieldProps["hint"] = "You’re not required to own a ‘Home’ phone, however if it’s left blank, our system will auto-populate this field with your mobile or work number.  This will not impact our ability to contact you if your pet is lost and found."
      }
    }

    this.props.updatePhoneNumbers(updatedPhoneNumberFields);
  }

  addNewEmergencyContactField(firstName, lastName, phone, email){
    let index = this.state.emergencyContactFields.length;
    let updateEmergencyContactFields = [...this.state.emergencyContactFields]
    updateEmergencyContactFields[index] = {
      firstNameInputProps:{
        value: firstName,
        name: "firstNameInputProps",
        maxLength: "50",
        error: false,
        hint: "",
        onChange: (event) => {
           this.changeInputHandler(event, index)
        }
      },
      lastNameInputProps:{
        value: lastName,
        name: "lastNameInputProps",
        maxLength: "50",
        error: false,
        hint: "",
        onChange: (event) => {
           this.changeInputHandler(event, index)
        }
      },
      phoneInputFieldProps:{
        value: phone,
        name: "phoneInputFieldProps",
        placeholder: "###-###-####",
        error: false,
        maxLength: "12",
        hint: "",
        onChange: (event) => {
           this.changeInputHandler(event, index)
        }
      },
      emailInputFieldProps:{
        value: email,
        name: "emailInputFieldProps",
        error: false,
        hint: "",
        onChange: (event) => {
           this.changeInputHandler(event, index)
        }
      }
    }
    this.setState({
      emergencyContactFields:updateEmergencyContactFields,
    },
    () =>{
      this.props.updateEmergencyContacts(this.state.emergencyContactFields);
    })
  }

  getPhoneTypeOptions(index, phoneNumberFields = this.state.phoneNumberFields) {
    let options = AppConstants.PHONEOPTIONS;
    if (index === 1) {
      let option1 = phoneNumberFields[0].selectPhoneFieldProps.value;
      options = options.filter((option) => option.value !== option1);
    }
    if (index === 2) {
      let option2 = phoneNumberFields[0].selectPhoneFieldProps.value;
      let option3 = phoneNumberFields[1].selectPhoneFieldProps.value;
      options = options.filter(function (option) {
        if (option.value === option2 || option.value === option3) {
          return false
        }
        return true;
      });
    }
    return options;
  }

  addExistingPhoneNumberFields(phoneNumbers) {
    let updatePhoneNumberFields = [];
    let currIndex = -1;
    phoneNumbers.forEach((phone) => {
      if (phone.number !== '') {
        currIndex = currIndex + 1;
        updatePhoneNumberFields[currIndex] = {
          selectPhoneFieldProps: {
            value: phone.type,
            name: "selectPhoneFieldProps",
            disabled: phone.number !== '' ? true : false,
            options: this.getPhoneTypeOptions(currIndex, updatePhoneNumberFields),
            onChange: (event) => {
              this.changePhoneInputHandler(event, currIndex)
            }
          },
          inputPhoneFieldProps: {
            value: phone.number,
            disabled: phone.number !== '' ? true : false,
            name: "inputPhoneFieldProps",
            placeholder: "###-###-####",
            maxLength: "12",
            error: false,
            hint: "",
            onChange: (event) => {
              this.changePhoneInputHandler(event, currIndex)
            }
          }
        }
        if(phone.type === "Home") {
          updatePhoneNumberFields[currIndex].inputPhoneFieldProps["hint"] = "You’re not required to own a ‘Home’ phone, however if it’s left blank, our system will auto-populate this field with your mobile or work number.  This will not impact our ability to contact you if your pet is lost and found."
        }
      }
    })

    this.setState({
      phoneNumberFields: updatePhoneNumberFields,
    },
    () => {
      this.props.updatePhoneNumbers(updatePhoneNumberFields);
    })
  }

  addNewPhoneNumberField(phoneType, phoneValue) {
    let index = this.state.phoneNumberFields.length;
    let updatePhoneNumberFields = [...this.state.phoneNumberFields];
    if (index !== 0) updatePhoneNumberFields[index - 1].selectPhoneFieldProps["disabled"] = true;
    updatePhoneNumberFields[index] = {
      selectPhoneFieldProps: {
        value: phoneType,
        name: "selectPhoneFieldProps",
        disabled: false,
        options: this.getPhoneTypeOptions(index),
        onChange: (event) => {
          this.changePhoneInputHandler(event, index)
        }
      },
      inputPhoneFieldProps: {
        value: phoneValue,
        name: "inputPhoneFieldProps",
        placeholder: "###-###-####",
        maxLength: "12",
        error: false,
        hint: "",
        onChange: (event) => {
          this.changePhoneInputHandler(event, index)
        }
      }
    }
    if(phoneType === "Home") {
      updatePhoneNumberFields[index].inputPhoneFieldProps["hint"] = "You’re not required to own a ‘Home’ phone, however if it’s left blank, our system will auto-populate this field with your mobile or work number.  This will not impact our ability to contact you if your pet is lost and found."
    }
    this.setState({
      phoneNumberFields: updatePhoneNumberFields,
    },
    () => {
      this.props.updatePhoneNumbers(updatePhoneNumberFields);
    }
    )
  }

  changeInputHandler = (event, index) =>{
    let updatedEmergencyContactsFields = this.state.emergencyContactFields;
    if (event.target.name === "phoneInputFieldProps") {
      const regex = /^[0-9\-]+$/;
      const prevValue = updatedEmergencyContactsFields[index][event.target.name].value;
      if (event.target.value.match(regex) || event.keyCode === 8 || event.target.value === "") {
        const number = AppUtils.formatPhoneNumber(event.target.value, prevValue);
        updatedEmergencyContactsFields[index][event.target.name].value = number;
      }
    } else {
      updatedEmergencyContactsFields[index][event.target.name].value = event.target.value;
    }
    this.props.updateEmergencyContacts(updatedEmergencyContactsFields);
  }

  componentDidMount(){
    if(this.state.emergencyContactFields.length){
      this.props.updateEmergencyContacts(this.state.emergencyContactFields);
    }
    if (this.state.phoneNumberFields.length) {
      this.props.updatePhoneNumbers(this.state.phoneNumberFields);
    } else {
      if (AppUtils.isEqualString(this.props.type, "phone")) {
        this.addExistingPhoneNumberFields(this.props.existingPhoneNumbers);
      } else {
        this.addNewPhoneNumberField("Home", "");
      }
      
    }
  }

  render() {
    const COUNTRIES = [
      {
      value: "",
      label: "Select One",
    },
    {
      value: "us",
      label: "United States",
    },
    {
      value: "ca",
      label: "Canada",
    },
  ]
    const optionStates = this.getStatesOrProvinces();
    // const primaryPhoneStatus = this.getPrimaryPhoneValidationStatus();
    // const isShowPhoneError = primaryPhoneStatus.showError;
    // const primaryPhoneErrorMessage = primaryPhoneStatus.errorMessage;

    return (
      <PageContent>
        <Steps
          steps={[
            {
              label: "Your Info",
              active: true,
              onClick: (event) => {
                this.props.onJumpPage(1);
              },
            },
            {
              label: "Pet Info",
              onClick: (event) => {
                this.props.onJumpPage(2);
              },
            },
            {
              label: "Review",
              onClick: (event) => {
                this.props.onJumpPage(3);
              },
            },
          ]}
          showSteps={this.props.formValues.showSteps}
        />
        <RmYourInfoCard
          errorMessage={this.props.formValues.ownerErrorMessage}
          onFormSubmit={() => {
            this.setState({
              isSubmitClicked: true,
            });
            this.props.saveOwnerRegistrationInfo();
          }}
          submitButtonProps={{
            disabled: this.getButtonState(),
          }}
          saveChangesButtonProps={{
            id: "saveButtonId",
            onClick: () => {},
          }}
          phoneNumberFieldsProps={this.state.phoneNumberFields}
          phoneLinkProps={{
            className: "dd-text-link-override",
            onClick: () => {
              let index = this.state.phoneNumberFields.length;
              let options = this.getPhoneTypeOptions(index);
              this.addNewPhoneNumberField(options[0].value, "");
            }
          }}
          closePhoneNumberProps={(index) => {
            this.removePhoneNumber(index);
          }}
          emailInputFieldProps={{
            value: this.props.formValues.email,
            name: "email",
            id: "email",
            disabled:true,
          }}
          stateSelectFieldProps={{
            value: this.props.formValues.state,
            options: optionStates,
            name: "state",
            id: "UpdateAccountInfo_State",
            placeholder: "Start typing...",
            helper: "",
            error: this.isStateRequired && this.state.isSubmitClicked,
            hint:
              this.isStateRequired && this.state.isSubmitClicked
                ? "Please select your state/ province before continuing."
                : "",
            onChange: (event) => {
              this.props.handleChanges(event, "state");
            },
          }}
          cityInputFieldProps={{
            value: this.props.formValues.city,
            name: "city",
            id: "UpdateAccountInfo_City",
            placeholder: "Example: Toronto",
            error: this.isCityRequired && this.state.isSubmitClicked,
            hint:
              this.isCityRequired && this.state.isSubmitClicked
                ? "Please fill out your city before continuing."
                : "",
            onChange: (event) => {
              this.props.handleChanges(event, "city");
            },
          }}
          streetAddressInputFieldProps={{
            value: this.props.formValues.street,
            name: "street",
            id: "UpdateAccountInfo_Street1",
            placeholder: "Example: 111 Queen St E",
            error: this.isStreetRequired && this.state.isSubmitClicked,
            hint:
              this.isStreetRequired && this.state.isSubmitClicked
                ? "Please fill out your street address before continuing."
                : "",
            onChange: (event) => {
              this.props.handleChanges(event, "street");
            },
          }}
          zipInputFieldProps={{
            value: this.props.formValues.zipCode,
            name: "zipCode",
            id: "UpdateAccountInfo_ZipCode",
            placeholder: "Example: 00000 or A1A 1A1",
            error: this.isZipCodeRequired && this.state.isSubmitClicked,
            hint:
              this.isZipCodeRequired && this.state.isSubmitClicked
                ? "Please fill out your zip code/postal code before continuing."
                : "",
            onChange: (event) => {
              this.props.handleChanges(event, "zipCode");
            },
          }}
          countrySelectFieldProps={{
            value: this.props.formValues.country,
            options: COUNTRIES,
            name: "country",
            id: "UpdateAccountInfo_Country",
            error: this.isCountryRequired && this.state.isSubmitClicked,
            hint:
              this.isCountryRequired && this.state.isSubmitClicked
                ? "Please select your country before continuing."
                : "",
            helper: "",
            onChange: (event) => {
              this.props.handleChanges(event, "country");
            },
          }}
          lastNameInputFieldProps={{
            value: this.props.formValues.lastName,
            name: "lastName",
            id: "lastName",
            placeholder: "Example: Smith",
            error: this.isLastNameRequired && this.state.isSubmitClicked,
            hint:
              this.isLastNameRequired && this.state.isSubmitClicked
                ? "Please fill out your last name before continuing."
                : "",
            onChange: (event) => {
              this.props.handleChanges(event, "lastName");
            },
          }}
          firstNameInputFieldProps={{
            value: this.props.formValues.firstName,
            name: "firstName",
            id: "firstName",
            placeholder: "Example: John",
            error: this.isFirstNameRequired && this.state.isSubmitClicked,
            hint:
              this.isFirstNameRequired && this.state.isSubmitClicked
                ? "Please fill out your first name before continuing."
                : "",
            onChange: (event) => {
              this.props.handleChanges(event, "firstName");
            },
          }}
          showCancelButton={this.props.formValues.isShowCancelStep1}
          cancelButtonProps={{
            id: "CancelStep1",
            variant:(FeatureFlagUtils.shouldShowInlineVariant())?Button.Variants.inline:Button.Variants.outlined,
            color: (FeatureFlagUtils.shouldShowInlineVariant())?Button.Colors.secondary:Button.Colors.tertiary,
            onClick: (event) => {
              this.props.onJumpPage(3);
            },
          }}
          linkProps={{
            className:"dd-text-link-override",
            onClick: () => {
              this.addNewEmergencyContactField("", "", "", "")
            }
          }}
          closeContactProps = { (index) =>{
            this.removeEmergencyContact(index);
          }}
          emergencyContactFields={this.state.emergencyContactFields}
          showAddContactLink={ (this.state.emergencyContactFields.length >= 2)?false: true }
        ></RmYourInfoCard>
      </PageContent>
    );
  }
}

export default RegistrationStep1;
