export class ApiConstants {
  // static URL_SERVER = "https://localhost:44379";
  static URL_SERVER = "";
  static URL_SOCIAL_LOGIN_GOOGLE = `${ApiConstants.URL_SERVER}/External/ExternalLogin?provider=Google`;
  static URL_SOCIAL_LOGIN_FACEBOOK = `${ApiConstants.URL_SERVER}/External/ExternalLogin?provider=Facebook`;
  static URL_CHANGE_SOCIAL_LOGIN_GOOGLE = `${ApiConstants.URL_SERVER}/External/ChangeToExternalLogin?provider=Google&userId=`;
  static URL_CHANGE_SOCIAL_LOGIN_FACEBOOK = `${ApiConstants.URL_SERVER}/External/ChangeToExternalLogin?provider=Facebook&userId=`;
  static URL_SOCIAL_SIGNUP_GOOGLE = `${ApiConstants.URL_SERVER}/External/SocialSignup?provider=Google`;
  static URL_SOCIAL_SIGNUP_FACEBOOK = `${ApiConstants.URL_SERVER}/External/SocialSignup?provider=Facebook`;
  static URL_APP_BASE = `${ApiConstants.URL_SERVER}/api`;
  static URL_LOGIN = `${ApiConstants.URL_APP_BASE}/identity/login`;
  static URL_CHECK_FA_MPH_USER = `${ApiConstants.URL_APP_BASE}/Identity/IsPasswordExist`
  static URL_LOGOUT = `${ApiConstants.URL_APP_BASE}/identity/logoff`;
  static URL_CHANGE_PASSWORD = `${ApiConstants.URL_APP_BASE}/identity/changepassword`;
  static URL_CHANGE_TO_EXTERNAL_LOGIN = `${ApiConstants.URL_APP_BASE}/identity/ChangeToInternalLogin`;
  static URL_EXTERNAL_LOGIN_USER_INFO = `${ApiConstants.URL_APP_BASE}/identity/ExternalLoginUserInfo`;
  //static URL_DASHBOARD_1 = `${ApiConstants.URL_APP_BASE}/Payment/GetBillingInfos/1757531`;
  static URL_DASHBOARD_1 = `${ApiConstants.URL_APP_BASE}/Animal/GetAnimalInfo/AUN13454026/BRUN12512952`;
  static URL_CUSTOMER_PROFILE = `${ApiConstants.URL_APP_BASE}/Customer/GetCustomerProfile/`;
  static URL_CUSTOMER_INFO = `${ApiConstants.URL_APP_BASE}/Customer/GetCustomerInfo/`;
  static URL_GET_POLICY = `${ApiConstants.URL_APP_BASE}/Insurance/GetInsurancePolicy/`;
  static URL_GET_CLAIM_HISTORY = `${ApiConstants.URL_APP_BASE}/Insurance/GetClaimsHistory/`;
  static URL_GET_RECOVERY_HISTORY = `${ApiConstants.URL_APP_BASE}/LostPet/LoadPetRecoveryHistory`;
  static URL_GET_CONTACT_INFO = `${ApiConstants.URL_APP_BASE}/Customer/GetContactInfo/`;
  static URL_GET_ANIMAL_INFO = `${ApiConstants.URL_APP_BASE}/Animal/GetAnimalInfo/`;
  static URL_GET_EMERGENCY_CONTACT = `${ApiConstants.URL_APP_BASE}/Customer/GetEmergencyContactsInfo/`
  static URL_POST_EMERGENCY_CONTACT = `${ApiConstants.URL_APP_BASE}/Customer/AddUpdateEmergencyContactInfo`;
  static URL_DELETE_EMERGENCY_CONTACT = `${ApiConstants.URL_APP_BASE}/Customer/DeleteEmergencyContactInfo`;
  static URL_POST_FORGOT_PASSWORD_EMAIL = `${ApiConstants.URL_APP_BASE}/Identity/ForgotPasswordEmailSend`;
  static URL_POST_SIGNUP_FIRST_STEP = `${ApiConstants.URL_APP_BASE}/Identity/ValidateRegisterStepOne`;
  static URL_POST_SIGNUP_SECOND_STEP = `${ApiConstants.URL_APP_BASE}/Identity/RegisterStepTwo`;
  static URL_POST_SIGNUP_SECOND_STEP_VERIFICATION = `${ApiConstants.URL_APP_BASE}/Identity/ValidateRegisterStepTwo`;
  static URL_POST_RESET_PASSWORD = `${ApiConstants.URL_APP_BASE}/Identity/ResetPasswordPost`;
  static URL_POST_UPDATE_PET_NAME = `${ApiConstants.URL_APP_BASE}/Animal/UpdateAnimalName`;
  static URL_POST_UPDATE_PET_PROFILE = `${ApiConstants.URL_APP_BASE}/Animal/UpdateAnimalProfile`;
  static URL_GET_CLAIM_DOCUMENT_URL = `${ApiConstants.URL_APP_BASE}/Insurance/GetActivityDocument/`;
  static URL_GET_INSURANCE_POLICY_DOCUMENT = `${ApiConstants.URL_APP_BASE}/Insurance/GetInsurancePolicyDocument/`;
  static URL_FOOTER_LOGOS_FOR_LOOGED_OUT = `${ApiConstants.URL_APP_BASE}/Identity/GetFooterForLoggedOutUser`;
  static URL_FOOTER_LOGOS_FOR_LOOGED_IN = `${ApiConstants.URL_APP_BASE}/Identity/GetFooterForLoggedInUser/`;
  static URL_REDIRECT_TO_SHOPPING_CART = `${ApiConstants.URL_APP_BASE}/Insurance/RedirectToShoppingCart/`;
  static URL_UPLOAD_ANIMAL_PHOTO = `${ApiConstants.URL_APP_BASE}/Animal/UploadAnimalPhoto/`;
  static URL_UPSERT_ANIMAL_PHOTO = `${ApiConstants.URL_APP_BASE}/Animal/UpsertAnimalPhoto/`;
  static URL_GET_PAYMENT_CARD_INFO = `${ApiConstants.URL_APP_BASE}/Payment/GetPaymentCardInfo/`;
  static URL_POST_CONTACT_INFO = `${ApiConstants.URL_APP_BASE}/Customer/UpdateContactInfo`;
  static URL_POST_UPDATE_PAPERLESS = `${ApiConstants.URL_APP_BASE}/Customer/UpdatePaperless`;
  static URL_GET_DIRECT_DEPOSIT_CLAIM = `${ApiConstants.URL_APP_BASE}/Payment/GetDirectDepositForClaim`;
  static URL_UPDATE_CARD_INFO = `${ApiConstants.URL_APP_BASE}/Payment/UpdatePaymentCardInfo`;
  static URL_GET_PAYMENT_HISTORY = `${ApiConstants.URL_APP_BASE}/Insurance/GetPaymentHistory`;
  static URL_ADD_MICROCHIP = `${ApiConstants.URL_APP_BASE}/Microchip/AddMicrochip/`;
  static URL_SAVE_MICROCHIP = `${ApiConstants.URL_APP_BASE}/Microchip/SaveMicrochip/`;
  static URL_OWNERSHIP_TRANSFER_FORM = `${ApiConstants.URL_APP_BASE}/Microchip/GetOwnershipTransferForm/`;
  static URL_GET_24PETMEDALERTFORM = `${ApiConstants.URL_APP_BASE}/Membership/Get24PetMedAlertForm`;
  static URL_GET_24PETMEDALERT_STATUS = `${ApiConstants.URL_APP_BASE}/Membership/Get24PetMedAlertStatus/`;
  static URL_REMOVE_PET = `${ApiConstants.URL_APP_BASE}/Animal/DeleteAnimalProfile`;
  static URL_GET_PET_PHOTO = `${ApiConstants.URL_APP_BASE}/Animal/GetAnimalPhoto/`;
  //Chiplet
  static URL_CHIPLET_PET_INFO = `${ApiConstants.URL_APP_BASE}/Chiplet/GetChipletPetInfo/`;
  static URL_CHIPLET_OWNER_INFO_BY_PET_ID = `${ApiConstants.URL_APP_BASE}/Chiplet/GetOwnerInfoByPetId/`;
  static URL_CHIPLET_OWNER_BY_OWNERSHIP = `${ApiConstants.URL_APP_BASE}/Chiplet/GetOwnerByOwnership/`;
  // Microchip
  static URL_MICROCHIP_NUMBER_VALIDATION = `${ApiConstants.URL_APP_BASE}/Microchip/NewChipRegValidation/`;
  static URL_MICRO_SAVE_OWNER_REG = `${ApiConstants.URL_APP_BASE}/Microchip/SaveOwnerRegistrationInfo`;
  static URL_MICRO_SAVE_PET_INFO = `${ApiConstants.URL_APP_BASE}/Microchip/SavePetRegistrationInfo`;
  static URL_MICRO_CHIP_COLORS_LIST = `${ApiConstants.URL_APP_BASE}/Microchip/LoadPetColorList`;
  static URL_MICRO_CHIP_CLINIC_LIST = `${ApiConstants.URL_APP_BASE}/Microchip/LoadClinicList`;
  static URL_MICRO_LOAD_PET_LIST = `${ApiConstants.URL_APP_BASE}/Microchip/LoadPetBreedList`;
  static URL_MICRO_SAVE_ADDITIONAL_INFO = `${ApiConstants.URL_APP_BASE}/Microchip/SaveAdditionalRegistrationInfo`;
  static URL_GET_EMAIL_CODE = `${ApiConstants.URL_APP_BASE}/Microchip/GetEmailConfirmCode`;
  static URL_VALIDATE_USER_CONFIRM_CODE = `${ApiConstants.URL_APP_BASE}/Microchip/ValidateUserAndEmailConfirmCode`;
  static URL_CONFIRM_PASSWORD = `${ApiConstants.URL_APP_BASE}/Microchip/CompleteRegistrationWithEmail`;
  static URL_EMAIL_ADDRESS_UNIQUENESS_VALIDATION = `${ApiConstants.URL_APP_BASE}/Microchip/ValidateEmailInDax/`;
  static URL_IS_USER_EXIST = `${ApiConstants.URL_APP_BASE}/Identity/IsUserExist/`;
  //Add Another Pet Using MC
  static URL_ADD_ANOTHER_PET_VALIDATION = `${ApiConstants.URL_APP_BASE}/Microchip/AddPetChipValidation`;

  //Membership Shopping Cart
  static URL_LOAD_AVAILABLE_MEMBERSHIP = `${ApiConstants.URL_APP_BASE}/Membership/LoadAvailableMembershipProducts/`;
  static URL_DISPLAY_CC_CARDS = `${ApiConstants.URL_APP_BASE}/Membership/DisplayCCForPayment/`;

  static URL_PLACE_NEW_ORDER = `${ApiConstants.URL_APP_BASE}/Shopping/PlaceNewOrders/`;
  static URL_LOAD_EXISTING_ORDERS = `${ApiConstants.URL_APP_BASE}/Shopping/LoadExistingOrders/`;
  static URL_APPLY_PROMOCODE = `${ApiConstants.URL_APP_BASE}/Shopping/ApplyPromoCode/`;
  static URL_REMOVE_ORDER = `${ApiConstants.URL_APP_BASE}/Shopping/RemoveOrder/`;
  static URL_CHECKOUT_SHOPPING_CART = `${ApiConstants.URL_APP_BASE}/Shopping/CheckOutShoppingCart/`;
  static URL_CHECKOUT_WITH_NEW_CARD = `${ApiConstants.URL_APP_BASE}/Shopping/CheckOutWithNewCard/`;
  static URL_REMOVE_ITEM = `${ApiConstants.URL_APP_BASE}/Shopping/RemoveItem/`;

  static URL_GET_MEMBERSHIP_PRODUCTS_OF_PET = `${ApiConstants.URL_APP_BASE}/Membership/GetMembershipProductsForPets`;
  static URL_SAVE_AUTO_RENEW_POST = `${ApiConstants.URL_APP_BASE}/Membership/SaveAutoRenewPost`;

  // Report Lost Pet
  static URL_REPORT_LOST_PET = `${ApiConstants.URL_APP_BASE}/LostPet/ReportLostPetPost`;
  static URL_REPORT_FOUND_PET = `${ApiConstants.URL_APP_BASE}/LostPet/ReportFoundPetPost`;
  static URL_GET_REPORT_LOST_LIST = `${ApiConstants.URL_APP_BASE}/LostPet/GetPetListForReportLostPet`;
  static URL_LOAD_REPORT_LOST_PET = `${ApiConstants.URL_APP_BASE}/LostPet/LoadReportLostPet`;
  static URL_LOAD_REPORT_FOUND_PET = `${ApiConstants.URL_APP_BASE}/LostPet/LoadReportFoundPet`;
  static URL_CLOSE_REPORT = `${ApiConstants.URL_APP_BASE}/LostPet/CloseLostPetReport`;
  static URL_GET_PET_STATUS = `${ApiConstants.URL_APP_BASE}/LostPet/GetPetMissingStatus`;

  // Status of policies.
  static URL_GET_STATUS_POLICY = `${ApiConstants.URL_APP_BASE}/Insurance/GetPolicyStatusForClaims`;
  static URL_CANCEL_POLICY_EMAIL = `${ApiConstants.URL_APP_BASE}/Insurance/SendPolicyCancellationEmail`
  // Contact us
  static URL_GET_ENQUIRY_LIST = `${ApiConstants.URL_APP_BASE}/Home/LoadInquiryListForContactUs`;
  static URL_POST_CONTACT_US = `${ApiConstants.URL_APP_BASE}/Home/ContactUsPost`;

  // User Profile Photo.
  static URL_UPLOAD_USER_PHOTO = `${ApiConstants.URL_APP_BASE}/Customer/UpsertUserImage`;
  static URL_GET_USER_IMAGE = `${ApiConstants.URL_APP_BASE}/Customer/DisplayUserImage`;

  // openclaim const
    static HANDLE_OPEN_CLAIM = "HANDLE_OPEN_CLAIM";

    static URL_GET_CLAIM_SUBMISSION_LIMIT_STATUS= `${ApiConstants.URL_APP_BASE}/Insurance/GetPolicyClaimSubmissionLimit`;

  // notification
  static URL_GET_NOTIFICATIONS = `${ApiConstants.URL_APP_BASE}/Notification/Alerts`;
  static URL_PUT_ACCEPT_CANCEL_NOTIFICATION_ALERT = `${ApiConstants.URL_APP_BASE}/Notification/Alerts`;
  static URL_GET_NOTIFICATION_DETAILS = `${ApiConstants.URL_APP_BASE}/Notification/Alerts`;
  static URL_CANCEL_LOST_PET_ALERTS = `${ApiConstants.URL_APP_BASE}/Notification/CancelLostPetAlerts`;

  //transfer pet
  static URL_POST_SAVE_TRANSFER_PET_OWNERSHIP_REQUEST = `${ApiConstants.URL_APP_BASE}/Notification/SaveTransferPetOwnershipPushRequest`;
  static URL_GET_TRANSFER_PET_OWNER_INFO = `${ApiConstants.URL_APP_BASE}/Customer/GetOwnerInfo`;

  //Reunification Contact
  static URL_GET_REUNIFICATION_CONTACTS = `${ApiConstants.URL_APP_BASE}/Animal/GetPRContact`;
  static URL_DELETE_REUNIFICATION_CONTACTS = `${ApiConstants.URL_APP_BASE}/Animal/RemovePRContact`
}
