import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class TwoColumnContent extends React.PureComponent {
  static propTypes = {
    leftColumn: PropTypes.any,
    rightColumn: PropTypes.any,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      leftColumn,
      rightColumn,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <div
        id={id}
        className={cn('ui-two-column-content-content', className)}
        style={style}
        ref={refProp}
      >
        <div className={cn('ui-two-column-content__column')}>{leftColumn}</div>
        <div className={cn('ui-two-column-content__column')}>{rightColumn}</div>
      </div>
    )
  }
}
