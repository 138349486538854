import {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  destroyAuthData,
} from "../reducers/auth-reducer";
import { destroyCustomerProfileData } from "../reducers/customer-profile-reducer";
import axios from "../../custom-axios";
import { ApiConstants } from "../../utils/api-constants";
import { DataLayerUtils } from "../../utils/dataLayer-utils";
import { AppUtils } from "../../utils/app-utils";

export function sendLoginRequest(data, history, elRef) {
  return (dispatch) => {
    let errors = [];
    dispatch(loginRequest());
    return axios
      .processPost(ApiConstants.URL_LOGIN, elRef, data)
      .then((response) => {
        dispatch(loginSuccess(response.data));
        DataLayerUtils.loginEvent("username and password", response.data.userRelationId);
        history.push("/pages");
      })
      .catch((err) => {
        errors = err?.response?.data?.errors;
        errors && dispatch(loginFailure(errors));
        if (errors && errors.length > 0) {
          DataLayerUtils.loginErrorEvent(errors[0]);
          DataLayerUtils.formErrorEvent("login", null, errors[0]);
        }
      });
  };
}

export function fetchUserDetailsForSocialLogin(history, elRef, accType, method, flow="") {
  return (dispatch) => {
    let errors = [];
    return axios
      .processGet(ApiConstants.URL_EXTERNAL_LOGIN_USER_INFO, elRef)
      .then((response) => {

        // from login page
        if (AppUtils.isEqualString(history?.location?.pathname, '/social-login')) {
          DataLayerUtils.loginEvent(
            null, // no way to identify, google or facebook
            response.data.userRelationId
          );
        }

        // from sign up page
        if (AppUtils.isEqualString(history?.location?.pathname, '/auth/signup/step2')) {
          DataLayerUtils.createAccountSuccessEvent(
            accType,
            response.data.userRelationId,
            null, // no petId
            flow,
            method // no way to identify, google or facebook
          )
        }
        dispatch(loginSuccess(response.data));
        history.push("/pages");
      })
      .catch((err) => {
        errors = err?.response?.data?.errors;
        errors && dispatch(loginFailure(errors));
        if (errors && error.length > 0) {

          if (AppUtils.isEqualString(history?.location?.pathname, '/social-login')) {
            DataLayerUtils.loginErrorEvent(errors[0]);
          }
        }
        history.push("/auth");
      });
  };
}

export function clearUserDataAfterLogout() {
  return (dispatch) => {
    dispatch(logoutSuccess());
    dispatch(destroyAuthData());
    dispatch(destroyCustomerProfileData());
  };
}
