import React from 'react'
import PropTypes from 'prop-types'

import DesktopPageHeader from './RmDesktopPageHeader'
import MobilePageHeader from './RmMobilePageHeader'

// Minimal width for the desktop layout to look good, after this break point render mobile view
const MOBILE_VIEW_BREAKPOINT = 927

export class RmPageHeader extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showMobile: window.innerWidth < MOBILE_VIEW_BREAKPOINT
    }

    this.handleViewBasedOnViewportWidth = this.handleViewBasedOnViewportWidth.bind(
      this
    )
  }

  static propTypes = {
    onLogoClick: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    showSubmitClaim: false,
    submitClaimButtonProps: {},
    showAccount: false,
    accountButtonProps: {},
    showPetMenu: false,
    showReportLostPetButton: true
  }

  handleViewBasedOnViewportWidth() {
    window.innerWidth < MOBILE_VIEW_BREAKPOINT
      ? this.setState({ showMobile: true })
      : this.setState({ showMobile: false })
  }

  componentDidMount() {
    this.handleViewBasedOnViewportWidth()

    window.addEventListener('resize', this.handleViewBasedOnViewportWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleViewBasedOnViewportWidth)
  }

  render() {
    return this.state.showMobile ? (
      <MobilePageHeader {...this.props} />
    ) : (
      <DesktopPageHeader {...this.props} />
    )
  }
}
