import React from "react";
import {
  PageContent,
  RmPetInfoCard,
  Steps,
  InputHelperWithTooltip,
  Button,
} from "../../../UI-Component-Library";
import { AppUtils } from "../../../utils/app-utils";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
class PetInformation extends React.Component {
  constructor(props) {
    super(props);

    // TODO: Remove State from here.
    this.state = {};
  }

  getContinueButtonState = () => {
    this.isMicrochipNumberRequired =
      this.props.formValues.microChipNumber.length > 0 ? false : true;

    this.isMicrochipProviderRequired =
      this.props.formValues.microchipProviderName.length > 0 ? false : true;

    this.isPetNameRequired =
      this.props.formValues.petName.length > 0 ? false : true;

    this.isPetGenderRequired =
      this.props.formValues.petGender.length > 0 ? false : true;

    this.isPetTypeRequired =
      this.props.formValues.petType.length > 0 ? false : true;

    this.isPetBreedRequired =
      this.props.formValues.petBreedLabel.length > 0 ? false : true;

    this.isPetColorRequired =
      this.props.formValues.petColor.length > 0 ? false : true;

    this.isBirthMonthRequired =
      this.props.formValues.birthMonth.length > 0 ? false : true;

    this.isBirthYearRequired =
      this.props.formValues.birthYear.length > 0 ? false : true;

    this.isClinicInfoRequired =
      this.props.formValues.clinicInfoLabel.length > 0 ? false : true;
    this.isSprayedRequired =
      this.props.formValues.isSprayed.length > 0 ? false : true;
    this.isConsentRequired =
      this.props.formValues.isConsent.length > 0 ? false : true;

    if (AppUtils.isEqualString(this.props.type, 'phone')) {
      this.isMicrochipNumberRequired = false;
      this.isMicrochipProviderRequired = false;
      this.isClinicInfoRequired = false;
    }

    if (
      !this.isMicrochipNumberRequired &&
      !this.isMicrochipProviderRequired &&
      !this.isPetNameRequired &&
      !this.isPetGenderRequired &&
      !this.isPetTypeRequired &&
      !this.isPetBreedRequired &&
      !this.isPetColorRequired &&
      !this.isBirthMonthRequired &&
      !this.isBirthYearRequired &&
      !this.isClinicInfoRequired &&
      !this.isSprayedRequired &&
      !this.isConsentRequired
    ) {
      return false;
    }
    return true;
  };

  getOptions = () => {
    const options = [];
    if (AppUtils.isEqualString(this.props.type, 'phone')) {
      return options;
    }
    for (let optionObj of this.props.formValues.chipProviders) {
      options.push({
        value: optionObj.Key,
        label: optionObj.Value,
      });
    }
    return options;
  };

  getBreedList = () => {
    const options = [];
    for (let optionObj of this.props.formValues.breedsList) {
      options.push({
        value: optionObj.Key,
        label: optionObj.Value,
      });
    }
    return options;
  };

  getClinicList = () => {
    let options = [];
    const list =
      this.props.formValues.country.toLowerCase() === "ca"
        ? this.props.formValues.canadaClinicList
        : this.props.formValues.usClinicList;
    if (list) {
      for (let optionObj of list) {
        if (
          ((!this.props.formValues.infoStart ||
            this.props.formValues.infoStart.length === 0) &&
            AppUtils.isEqualString(
              optionObj.Key,
              this.props.formValues.clinicInfo
            )) ||
          (this.props.formValues.infoStart?.length >= 3 &&
            optionObj.Value.toLowerCase().indexOf(
              this.props.formValues.infoStart?.toLowerCase()
            ) !== -1)
        ) {
          options.push({
            value: optionObj.Key,
            label: optionObj.Value,
          });
        }
      }
    }
    options = options.slice(0, 200);
    if (
      this.props.formValues.infoStart &&
      this.props.formValues.infoStart.length > 0
    ) {
      options.push({
        value: "other",
        label: "Other",
      });
    }
    return options;
  };

  getColorList = () => {
    const options = [];
    if (this.props.formValues.country.toLowerCase() === "ca") {
      for (let optionObj of this.props.formValues.canadaColorList) {
        options.push({
          value: optionObj.Key,
          label: optionObj.Value,
        });
      }
      return options;
    }

    if (this.props.formValues.country.toLowerCase() === "us") {
      for (let optionObj of this.props.formValues.usColorList) {
        options.push({
          value: optionObj.Key,
          label: optionObj.Value,
        });
      }
      return options;
    }
  };

  render() {
    const optionMicrochipProvider = this.getOptions();
    const breedList = this.getBreedList();
    const clinicList = this.getClinicList();

    const colorList = this.getColorList();
    const birthYears = AppUtils.getBirthYears();

    return (
      <PageContent>
        <Steps
          steps={[
            {
              label: "Pet Info",
              active: true,
            },
            {
              label: "Review",
            },
          ]}
          showSteps={true}
        />
        <RmPetInfoCard
          onFormSubmit={() => { }}
          type = {this.props.type}
          microchipNumberInputFieldProps={{
            name: "microChipNumber",
            id: "petMicrochipId",
            helper: (
              <InputHelperWithTooltip
                helperContent="How do I find my pet's microchip number?"
                tooltipContent="The microchip number can be found on your pet’s adoption form, the length can vary from 8 to15 digits. 
                Most numbers start with 982, 985, or 60. Others may start with (not limited to) 0, 1, 8, 60, 977, etc. If you need further assistance, call 1-866-597-2424."
              />
            ),
            disabled: true,
            value: this.props.formValues.microChipNumber,
            onChange: (event) => {
              this.props.handleChanges(event, "microChipNumber");
            },
          }}
          microchipProviderSelectFieldProps={{
            name: "microchipProvider",
            id: "petProviderId",
            value: this.props.formValues.microchipProvider,
            options: optionMicrochipProvider,
            helper: (
              <InputHelperWithTooltip
                helperContent="Where do I find information about my pet's microchip provider?"
                tooltipContent={
                  <>
                    Manufacturers have their own unique code see if your chip
                    number sequence matches any of these. If not choose ‘other’{" "}
                    <br></br>24Petwatch -982, 941 or 0A1<br></br> Datamars -981{" "}
                    <br></br>Homeagain -985 <br></br>AKC- 958 <br></br>Banfield
                    -98101000 or 0D0D <br></br>AVID- 977 or ‘xxx-xxx-xxx’
                  </>
                }
              />
            ),
            placeholder: "Enter microchip provider name",
            onChange: (event) => {
              this.props.handleChanges(event, "microchipProvider");
            },
          }}
          petNameInputFieldProps={{
            name: "petName",
            id: "petNameId",
            placeholder: "Example: Bonita",
            value: this.props.formValues.petName,
            helper:
              "This can be your pet's current name, or a new name that you chose.",
            onChange: (event) => {
              this.props.handleChanges(event, "petName");
            },
          }}
          petTypeChipProps={{
            name: "petType",
            value: this.props.formValues.petType,
            items: [
              {
                value: "Dog",
                label: "Dog",
                buttonId: "petTypeId1"
              },
              {
                value: "Cat",
                label: "Cat",
                buttonId: "petTypeId2"
              },
            ],
            onChange: (event) => {
              this.props.handleChanges(event, "petType");
            },
          }}
          petGenderChipProps={{
            name: "petGender",
            value: this.props.formValues.petGender,
            items: [
              {
                value: "Male",
                label: "Male",
                buttonId: "petGenderId1"
              },
              {
                value: "Female",
                label: "Female",
                buttonId: "petGenderId2"
              },
            ],
            onChange: (event) => {
              this.props.handleChanges(event, "petGender");
            },
          }}
          breedTypeChipProps={{
            name: "breedType",
            value: this.props.formValues.breedType,
            items: [
              {
                value: "Purebred",
                label: "Purebred",
                buttonId: "petBreedId1"
              },
              {
                value: "Mixed",
                label: "Mixed",
                buttonId: "petInfoBreedId2"
              },
            ],
            onChange: (event) => {
              this.props.handleChanges(event, "breedType");
            },
          }}
          breedSelectFieldProps={{
            name: "petBreed",
            id:"petBreedSelectId",
            helper: `If you don't see it in the list below, select "Other" at very bottom.`,
            value: this.props.formValues.petBreed,
            placeholder: "Start typing...",
            options: breedList,
            onChange: (event) => {
              this.props.handleChanges(event, "petBreed");
            },
          }}
          colorSelectFieldProps={{
            name: "petColor",
            id:"petColorSelectId",
            value: this.props.formValues.petColor,
            helper: `If you don't see your pet's color, please select "other" located at the bottom of the list`,
            placeholder: "Start typing...",
            options: colorList,
            onChange: (event) => {
              this.props.handleChanges(event, "petColor");
            },
          }}
          birthMonthSelectFieldProps={{
            name: "birthMonth",
            id:"petMonthId",
            label: "Birth Month: (required)",
            placeholder: "Month",
            value: this.props.formValues.birthMonth,
            options: AppUtils.birthMonths,
            onChange: (event) => {
              this.props.handleChanges(event, "birthMonth");
            },
          }}
          birthYearSelectFieldProps={{
            name: "birthYear",
            id:"petYearId",
            placeholder: "Year",
            label: "Birth Year: (required)",
            value: this.props.formValues.birthYear,
            options: birthYears,
            onChange: (event) => {
              this.props.handleChanges(event, "birthYear");
            },
          }}
          clinicSelectFieldProps={{
            name: "clinicInfo",
            id:"petClinicId",
            value: this.props.formValues.clinicInfo
              ? this.props.formValues.clinicInfo
              : "",
            options: clinicList,
            helper: `If you don't see it in the list below, select "Other" at very bottom.`,
            placeholder: "Start typing...",
            onChange: (event) => {
              this.props.handleChanges(event, "clinicInfo");
            },
            onInputChange: (event) => {
              this.props.handleChanges(event, "infoStart");
            },
          }}
          spayedOrNeuteredChipProps={{
            name: "isSprayed",
            value: this.props.formValues.isSprayed,
            items: [
              {
                value: "Yes",
                label: "Yes",
                buttonId: "petSpayedId1"
              },
              {
                value: "No",
                label: "No",
                buttonId: "petSpayedId2"
              },
            ],
            onChange: (event) => {
              this.props.handleChanges(event, "isSprayed");
            },
          }}
          consentChipProps={{
            name: "isConsent",
            items: [
              {
                value: "Yes",
                label: "Yes",
                buttonId: "petConsentId1"
              },
              {
                value: "No",
                label: "No",
                buttonId: "petConsentId2"
              },
            ],
            label:
              "Do you consent to the release of my name, email, address and telephone number to anyone who finds your pet? (required)",
            value: this.props.formValues.isConsent,
            onChange: (event) => {
              this.props.handleChanges(event, "isConsent");
            },
          }}
          submitButtonProps={{
            id: "petSubmitId",
            disabled: this.getContinueButtonState(),
            onClick: (event) => {
              let isFutureDate = false;
              const currYear = new Date().getFullYear();
              const currMonth = new Date().getMonth() + 1;

              if(parseInt(this.props.formValues.birthYear) === currYear
                && parseInt(this.props.formValues.birthMonth) > currMonth
              ){
                isFutureDate = true;
              }
              this.props.savePetRegistrationInfo(isFutureDate);
            },
          }}
          errorMessage={this.props.formValues.savePetErrorMessage}
          showCancelButton={this.props.formValues.isShowCancelStep}
          cancelButtonProps={{
            id: "CancelStep1",
            variant:(FeatureFlagUtils.shouldShowInlineVariant())?Button.Variants.inline:Button.Variants.outlined,
            color: (FeatureFlagUtils.shouldShowInlineVariant())?Button.Colors.secondary:Button.Colors.tertiary,
            onClick: (event) => {
              this.props.onJumpPage(2);
            },
          }}
        ></RmPetInfoCard>
      </PageContent>
    );
  }
}

export default PetInformation;
