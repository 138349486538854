import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Form,
  Input,
  FormAction,
  Button,
  Text,
  Select,
  SelectWithSearch
} from '../..'
import { ToggleButtonGroup } from '../ToggleButtonGroup'

export class RmPetInfoCard extends React.PureComponent {
  static propTypes = {
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,

    microchipNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    microchipProviderSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,
      id: PropTypes.string
    }),
    petNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    petTypeChipProps: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      label: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      id: PropTypes.string
    }),
    petGenderChipProps: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      label: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      id: PropTypes.string
    }),
    breedTypeChipProps: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      label: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      id: PropTypes.string
    }),

    breedSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    colorSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),

    birthMonthSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    birthYearSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    clinicSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),

    submitButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    showCancelButton: PropTypes.bool,
    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    spayedOrNeuteredChipProps: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      label: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      id: PropTypes.string
    }),
    consentChipProps: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      label: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      id: PropTypes.string,
    }),
    type: PropTypes.string,
  }

  render() {
    const {
      successMessage,
      errorMessage,
      onFormSubmit,
      microchipNumberInputFieldProps,
      microchipProviderSelectFieldProps,
      petNameInputFieldProps,
      breedSelectFieldProps,
      colorSelectFieldProps,
      birthMonthSelectFieldProps,
      birthYearSelectFieldProps,
      clinicSelectFieldProps,
      petTypeChipProps,
      petGenderChipProps,
      breedTypeChipProps,
      submitButtonProps,
      cancelButtonProps,
      showCancelButton,
      spayedOrNeuteredChipProps,
      consentChipProps,
      id,
      className,
      style,
      refProp,
      type,
    } = this.props

    const isPhone = type?.toLowerCase() === 'phone'

    return (
      <Paper
        id={id}
        className={cn('ui-user-contact-info-card', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Pet info</PaperHeadline>
        <PaperContent>
          <Text type={Text.Types.body} className='sub-heading'>
            {"Now let's get to know your pet a little better."}
          </Text>

          <Form onSubmit={onFormSubmit} className='c-form'>
            {successMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-user-contact-info-card__success-message'
              >
                {successMessage}
              </Text>
            )}

            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-user-contact-info-card__error-message'
              >
                {errorMessage}
              </Text>
            )}
            {!isPhone && (<Input
              label='Microchip Number: (required)'
              {...microchipNumberInputFieldProps}
            />)}
            {!isPhone && (<Select
              label='Microchip Provider: (required)'
              {...microchipProviderSelectFieldProps}
            />)}

            <Input label='Pet Name: (required)' {...petNameInputFieldProps} />
            <ToggleButtonGroup
              label='Is your pet a: (required)'
              {...petTypeChipProps}
            />
            <ToggleButtonGroup
              label="What's their gender? (required)"
              {...petGenderChipProps}
            />
            <ToggleButtonGroup
              label="What's their breed type? (required)"
              {...breedTypeChipProps}
            />
            <SelectWithSearch
              helper='If you don’t see it in the list below, select “Other” at the very bottom.'
              label='Breed: (required)'
              {...breedSelectFieldProps}
              searchable
            />
            <SelectWithSearch
              helper='If you don’t see it in the list below, select “Other” at the very bottom.'
              label='Color: (required)'
              {...colorSelectFieldProps}
              searchable
            />
            <ToggleButtonGroup
              label='Are they spayed or neutered? (required)'
              {...spayedOrNeuteredChipProps}
            />

            <div className='ui-user-contact-info-card__form__row'>
              <Select label='Month' {...birthMonthSelectFieldProps} />
              <Select label='Year' {...birthYearSelectFieldProps} />
            </div>
            {!isPhone && (<SelectWithSearch
              helper='If you don’t see it in the list below, select “Other” at the very bottom.'
              label='Which clinic microchipped your pet: (required)'
              useFilterOptions={true}
              {...clinicSelectFieldProps}
              searchable
            />)}
            <Text type={Text.Types.body}>
              Please ensure that all the information is complete and accurate.
              We may contact you periodically to ensure your information remains
              accurate and up to date.
            </Text>

            <ToggleButtonGroup
              label='Do you consent to the release of my name, address and telephone number to anyone who finds your pet? (required)'
              {...consentChipProps}
            />

            <Text type={Text.Types.body}>
              You may withdraw your consent at any time.
            </Text>
            
            <FormAction>
              {showCancelButton && (
                <Button
                  className='rm-ui-pet-info-card-action-button'
                  color={Button.Colors.secondary}
                  variant={Button.Variants.outlined}
                  {...cancelButtonProps}
                >
                  Cancel
                </Button>
              )}
              <Button
                color={Button.Colors.tertiary}
                type='submit'
                {...submitButtonProps}
              >
                Continue
              </Button>
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}
