import React from "react";
import { EmergencyContactCard } from "../../../../UI-Component-Library";
import axios from "../../../../custom-axios";
import { ApiConstants } from "../../../../utils/api-constants";
import { ValidationMessages } from "../../../../utils/validation-messages";
import { AppUtils } from "../../../../utils/app-utils";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { DataLayerUtils } from "../../../../utils/dataLayer-utils";

class EmergencyContact extends React.Component {
  constructor() {
    super();
    this.state = {
      emergencyContactFields: [],
      errorMessage: "",
      successMessage: "",
      emergencyContactDetails: [],
    };
    this.cRef = React.createRef();
  }

  getEmergencyContactInfo(){
      // call api to get emergency contact details
      axios
        .processGet(
          `${ApiConstants.URL_GET_EMERGENCY_CONTACT}${AppUtils.getBusRelAccount()}`,
          this.cRef,
        )
        .then((response) => {
          this.setState({
            emergencyContactFields: [],
            emergencyContactDetails: [],
          })

          if(response.length === 0){
            this.addNewEmergencyContactField("","","","", false);
          }else{
            let ECDetails = [];
            response.forEach((value,index) => {
              if(value.isPendingRemoval && response.length === 1){
                this.addNewEmergencyContactField("","","","", false);
              }
              if (!value.isPendingRemoval) {
                ECDetails.push(value);
                this.addNewEmergencyContactField(value.firstName,value.lastName,value.phoneNumber,value.email, value.isPending);
              }
            })
            this.setState({
              emergencyContactDetails: ECDetails,
            })
          }
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              errorMessage: errors[0],
            });
          }
        })
      // this.addNewEmergencyContactField();
  }  

  checkValidData(value, field){
     if(!AppUtils.isEmptyString(value)){
       if(field==="Phone Number" && !AppUtils.isValidPhoneNumber(value)){
        return {
          error: !AppUtils.isValidPhoneNumber(value),
          hint:ValidationMessages.INVALID_PHONE_NUMBER
        }
       }
      if(field === "Email Address" && !AppUtils.isValidEmail(value)){
        return {
          error: !AppUtils.isValidEmail(value),
          hint:ValidationMessages.EMAIL_VALIDATION_MESSAGE
        }
      }

      return {
        error:false,
        hint:""
      }
     }else{
       return {
         error:true,
         hint:`${field} is required field`
       }
     }
  }

  compareEmergencyContacts(contacts){
    if(contacts.length<=1){
      return false;
    }
    if(AppUtils.isEqualString(contacts[0].firstName, contacts[1].firstName) &&
      AppUtils.isEqualString(contacts[0].lastName, contacts[1].lastName) &&
      AppUtils.isEqualString(contacts[0].email, contacts[1].email) &&
      AppUtils.isEqualString
      (
        AppUtils.removePhoneFormatting(contacts[0].phoneNumber),
        AppUtils.removePhoneFormatting(contacts[1].phoneNumber)
      )
    ){
      return true;
    }

    return false;
  }

  updateContactFields(updateEmergencyContactFields, firstName, lastName, phone, email, index){
    updateEmergencyContactFields[index].firstNameInputProps.error=firstName.error;
    updateEmergencyContactFields[index].firstNameInputProps.hint = firstName.hint;
    updateEmergencyContactFields[index].lastNameInputProps.error=lastName.error;
    updateEmergencyContactFields[index].lastNameInputProps.hint=lastName.hint;
    updateEmergencyContactFields[index].phoneInputFieldProps.error=phone.error;
    updateEmergencyContactFields[index].phoneInputFieldProps.hint=phone.hint;
    updateEmergencyContactFields[index].emailInputFieldProps.error=email.error;
    updateEmergencyContactFields[index].emailInputFieldProps.hint=email.hint;

    return updateEmergencyContactFields;
  }

  updateEmergencyContact(){
    let payload = [],
        error = false,
        updateEmergencyContactFields = this.state.emergencyContactFields;

    this.setState({
      errorMessage: "",
      successMessage: ""
    })

    this.state.emergencyContactFields.forEach((contact,index) => {
      let firstName,
          lastName,
          phone,
          email;
      firstName = this.checkValidData(contact.firstNameInputProps.value, "First Name");
      lastName = this.checkValidData(contact.lastNameInputProps.value, "Last Name");
      phone = this.checkValidData(contact.phoneInputFieldProps.value, "Phone Number");
      email = this.checkValidData(contact.emailInputFieldProps.value, "Email Address");

      if(firstName.error || lastName.error || phone.error || email.error){
        error=true;
      }

      updateEmergencyContactFields = this.updateContactFields(updateEmergencyContactFields, firstName, lastName, phone, email, index)
      let ECDetails = this.state.emergencyContactDetails;
      let contactPersonId = (ECDetails.length === 0 || ECDetails.length === index) ? '' : ECDetails[index].contactPersonId;
      let temporalIdentifier = (ECDetails.length === 0 || ECDetails.length === index) ? 0 : ECDetails[index].temporalIdentifier;

      payload.push({
        "busRelAccount": AppUtils.getBusRelAccount(),
        "contactPersonId": contactPersonId,
        "temporalIdentifier": temporalIdentifier,
        "firstName": contact.firstNameInputProps.value,
        "lastName": contact.lastNameInputProps.value,
        "email": contact.emailInputFieldProps.value,
        "phoneNumber": contact.phoneInputFieldProps.value,
        "country": AppUtils.getCountryCode(),
      })
    })

    if(error){
      this.setState({
        emergencyContactFields:updateEmergencyContactFields
      })
    }else{
      let isEqualEC = this.compareEmergencyContacts(payload);
      if(isEqualEC){
        this.setState({
          errorMessage: "Emergency Contact already exists. Please provide alternate contact details or remove this Emergency Contact",
          emergencyContactFields: updateEmergencyContactFields
        })
        AppUtils.focusOnDiv("emergency_contact");
        return;
      }

      this.setState({
        errorMessage:"",
      })

      axios
        .processPost(
          `${ApiConstants.URL_POST_EMERGENCY_CONTACT}`,
          this.cRef,
          payload
        )
        .then((response) => {
          this.setState({
            successMessage: "Emergency Contacts Info updated successfully!"
          })

          this.getEmergencyContactInfo();
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              errorMessage: errors[0],
            });
            DataLayerUtils.formErrorEvent("Emergency Contacts", null, errors[0]);
          } else {
            this.setState({
              errorMessage: "Error while updating the Info!",
            });
            DataLayerUtils.formErrorEvent("Emergency Contacts", null, "Error while updating the Info!");
          }
        })
    }
  }

  componentDidMount() {
    this.getEmergencyContactInfo();
  }

  changeInputHandler = (event, index) =>{
    let updatedEmergencyContactsFields = this.state.emergencyContactFields;

    if (AppUtils.isEqualString(event.target.name, "phoneInputFieldProps")) {
      let phoneNumberElement = event.target.value;
      console.log(AppUtils.isTypeNumber(phoneNumberElement));

      // Only number and '-' allowed
      if ((AppUtils.isTypeNumber(event.target.value)
        || event.keyCode === 8
        || event.target.value === "")
      ) {
        const phoneElements = phoneNumberElement.split("");
        if (
          phoneNumberElement.length === 4 &&
          updatedEmergencyContactsFields[index]["phoneInputFieldProps"].value.length !== 5
        ) {
          phoneNumberElement =
            phoneNumberElement.substr(0, 3) + "-" + phoneElements[3];
        }

        if (
          phoneNumberElement.length === 8 &&
          updatedEmergencyContactsFields[index]["phoneInputFieldProps"].value.length !== 9
        ) {
          const phoneElements = phoneNumberElement.split("");
          phoneNumberElement =
            phoneNumberElement.substr(0, 7) + "-" + phoneElements[7];
        }

        updatedEmergencyContactsFields[index][event.target.name].value = phoneNumberElement;
        this.setState({
          emergencyContactFields: updatedEmergencyContactsFields,
        })
      }

      return;
    }

    updatedEmergencyContactsFields[index][event.target.name].value = event.target.value;
    this.setState({
      emergencyContactFields:updatedEmergencyContactsFields,
    })
  }

  addNewEmergencyContactField(firstName, lastName, phone, email, isDisable){
    let index = this.state.emergencyContactFields.length;
    let updateEmergencyContactFields = [...this.state.emergencyContactFields]
    updateEmergencyContactFields[index] = {
      firstNameInputProps:{
        value: firstName,
        name:"firstNameInputProps",
        error:false,
        hint:"",
        disabled: isDisable,
        onChange: (event) => {
           this.changeInputHandler(event, index)
        }
      },
      lastNameInputProps:{
        value: lastName,
        name:"lastNameInputProps",
        error:false,
        hint:"",
        disabled: isDisable,
        onChange: (event) => {
           this.changeInputHandler(event, index)
        }
      },
      phoneInputFieldProps:{
        value: phone,
        name:"phoneInputFieldProps",
        error:false,
        hint: "",
        maxLength:"12",
        disabled: isDisable,
        onChange: (event) => {
           this.changeInputHandler(event, index)
        }
      },
      emailInputFieldProps:{
        value: email,
        name:"emailInputFieldProps",
        error:false,
        hint:"",
        disabled: isDisable,
        onChange: (event) => {
           this.changeInputHandler(event, index)
        }
      }
    }
    this.setState({
      emergencyContactFields:updateEmergencyContactFields,
    })
  }

  removeEmergencyContact(index){
    this.setState({
      errorMessage: "",
      successMessage: ""
    });
    if(index === this.state.emergencyContactDetails.length || !this.state.emergencyContactDetails.length ){
      let emergencyContactFields = this.state.emergencyContactFields;
      emergencyContactFields.splice(index, 1);
      this.setState({
        emergencyContactFields,
      })
      return;
    }
    let ECDetails  = this.state.emergencyContactDetails,
        busRelAccount = ECDetails[index].busRelAccount,
        contactPersonId = (ECDetails[index].contactPersonId) ? ECDetails[index].contactPersonId: "",
        temporalIdentifier = (ECDetails[index].contactPersonId) ? 0 : ECDetails[index].temporalIdentifier;

    const payload = {
      "busRelAccount": busRelAccount,
      "contactPersonId": contactPersonId,
      "temporalIdentifier": temporalIdentifier
    }

    axios
    .processDelete(
      `${ApiConstants.URL_DELETE_EMERGENCY_CONTACT }`,
      this.cRef,
      payload
    )
    .then((response) => {
      this.setState({
        successMessage: "Emergency Contact Info removed successfully!"
      })

      this.getEmergencyContactInfo();
    })
    .catch((err) => {
      let errors = err?.response?.data?.errors;
      if (errors && errors.length > 0) {
        this.setState({
          errorMessage: errors[0],
        });
      } else {
        this.setState({
          errorMessage: "Error while deleting the Info!",
        });
      }
    })

  }
  
  render() {
    return (
      <div ref={this.cRef} id="emergency_contact">
        <EmergencyContactCard 
          emergencyContactFields={this.state.emergencyContactFields}
          showAddContactLink={ (this.state.emergencyContactFields.length >= 2)?false: true }
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          showCrossIcon={true}
          linkProps={{
            className:"dd-text-link-override",
            onClick: () => {
              this.addNewEmergencyContactField("","","","", false);
            }
          }}
          closeContactProps = { (index) =>{
            this.removeEmergencyContact(index);
          }}
          onFormSubmit={()=>{
            this.updateEmergencyContact();
          }} 
        />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default connect(null, mapDispatchToProps)(withRouter(EmergencyContact));
