import React from 'react'
import cn from 'classnames'

import { LogoSvg, PageHeaderProps } from './RmPageHeaderComponents'

const DesktopPageHeader = ({ onLogoClick, id, className, style, refProp }) => {
  return (
    <div
      id={id}
      className={cn('rm-ui-page-header', className)}
      style={style}
      ref={refProp}
    >
      <div
        className='rm-ui-page-header__logo'
        role='button'
        onClick={onLogoClick}
      >
        <LogoSvg />
      </div>
    </div>
  )
}
DesktopPageHeader.propTypes = PageHeaderProps

export default DesktopPageHeader
