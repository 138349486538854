import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import "./App.scss";
import AuthContainer from "./components/auth/authcontainer";
import Pages from "./components/pages/pages";
import PrivateRoute from "./utils/privateroute";
import { AppUtils } from "./utils/app-utils";
import SocialLoginHandler from "./components/auth/social-login-handler";
import { connect } from "react-redux";
import { clearUserDataAfterLogout } from "./redux/actions/authActions";
import { PageBanner } from "./UI-Component-Library";
import RegisterMicrochipContainer from "./components/auth/register-microchip/register-microchip-container";
import PasswordCreation from "./components/auth/create-password/password-create";
import ShoppingCartWrapper from "./components/pages/new-payment/shopping-cart-wrapper";
import axios from "axios";
import { AppConstants } from "./utils/app-constants";
import { ApiConstants } from "./utils/api-constants";
import { FeatureFlagUtils } from "./utils/feature-flag-utils";
import { LoadMask } from "./utils/loadmask.util";
class App extends Component {
  constructor() {
    super();
    this.state = {
      isGenericError: false,
      isConfigLoaded: false,
      isThemeConfigLoaded: false,
      configLoadingError: "",
    };
    this.cRef = React.createRef();
  }
  componentDidMount() {
    const divRef = this.cRef.current;
    AppUtils.saveCallbacks(this.logOutUser, this.showGenericError, this);
    LoadMask.showPreloader(divRef);
    axios
      .get("/config.json")
      .then((config) => {
        AppConstants.CONFIG_JSON = config.data;
        ApiConstants.URL_SERVER = AppConstants.CONFIG_JSON.URL_SERVER;
        window.url_server = AppConstants.CONFIG_JSON.URL_SERVER;
        document.getElementById("page-title").innerHTML =
          config.data.PAGE_TITLE;
        //Loading Theme Configuration File
        axios
          .get("/themes/theme_" + config.data.Program + ".json")
          .then((theme_config) => {
            AppConstants.CONFIG_THEME_JSON = theme_config.data;
            loadFavicon();
            loadColours();
            loadFonts();

            this.setState({
              isThemeConfigLoaded: true,
              configLoadingError: "",
            });
            LoadMask.hidePreloader(divRef);
          })
          .catch((err) => {
            LoadMask.hidePreloader(divRef);
            this.setState({
              isThemeConfigLoaded: false,
              configLoadingError: "Theme Configuration file not loaded",
            });
          });

        this.setState({
          isConfigLoaded: true,
          configLoadingError: "",
        });
      })
      .catch((err) => {
        LoadMask.hidePreloader(divRef);
        this.setState({
          isConfigLoaded: false,
          configLoadingError: "Configuration file not loaded",
        });
      });
  }
  logOutUser(thisRef) {
    console.log("Logging user out", thisRef);
    thisRef.props.logOut();
    AppUtils.clearLoginData();
    thisRef.props.history.replace("/auth/login");
  }
  showGenericError(thisRef) {
    thisRef.setState({
      isGenericError: true,
    });
  }
  render() {
    return (
      <div ref={this.cRef} className={(FeatureFlagUtils.shouldOverrideHeadingFont())?"fontOverride":""}>
        {this.state.isConfigLoaded ? (
          <div>
            {this.state.isGenericError && (
              <PageBanner
                title="Oh No. It looks like one of the pets chewed a cord."
                subtitle={`Please try again later or call ${FeatureFlagUtils.getPhoneNumber()}.`}
              />
            )}
            {this.props.history.location.pathname === "/" ? (
              <Route
                path="/"
                render={() => {
                  return AppUtils.isAuthenticated() ? (
                    <Redirect to="/pages" />
                  ) : (
                    <Redirect to="/auth" />
                  );
                }}
              ></Route>
            ) : null}
            <Switch>
              <PrivateRoute path="/pages">
                <Pages></Pages>
              </PrivateRoute>
              <Route path="/auth" component={AuthContainer}></Route>
              <Route
                path="/social-login"
                component={SocialLoginHandler}
              ></Route>
              <Route
                path="/register-pet"
                component={RegisterMicrochipContainer}
              ></Route>
              <Route
                path="/cart-v2"
                component={ShoppingCartWrapper}
              ></Route>
              <Route path="/verify-user" component={PasswordCreation}></Route>
            </Switch>
          </div>
        ) : (
          <div>{this.state.configLoadingError}</div>
        )}
      </div>
    );
  }
}

function loadColours() {
  var bodyStyles = document.body.style;
  bodyStyles.setProperty('--color-primary-400', `${AppConstants.CONFIG_THEME_JSON.COLOR_PRIMARY_400}`); 
  bodyStyles.setProperty('--color-black', `${AppConstants.CONFIG_THEME_JSON.COLOR_BLACK}`);
  bodyStyles.setProperty('--color-secondary-100', `${AppConstants.CONFIG_THEME_JSON.COLOR_SECONDARY_100}`); 
  bodyStyles.setProperty('--color-secondary-300', `${AppConstants.CONFIG_THEME_JSON.COLOR_SECONDARY_300}`); 
  bodyStyles.setProperty('--color-secondary-400', `${AppConstants.CONFIG_THEME_JSON.COLOR_SECONDARY_400}`); 
  bodyStyles.setProperty('--color-secondary-500', `${AppConstants.CONFIG_THEME_JSON.COLOR_SECONDARY_500}`); 
  bodyStyles.setProperty('--color-tertiary-100', `${AppConstants.CONFIG_THEME_JSON.COLOR_TERTIARY_100}`); 
  bodyStyles.setProperty('--color-tertiary-300', `${AppConstants.CONFIG_THEME_JSON.COLOR_TERTIARY_300}`); 
  bodyStyles.setProperty('--color-tertiary-400', `${AppConstants.CONFIG_THEME_JSON.COLOR_TERTIARY_400}`); 
  bodyStyles.setProperty('--color-tertiary-500', `${AppConstants.CONFIG_THEME_JSON.COLOR_TERTIARY_500}`); 
  bodyStyles.setProperty('--color-success-400', `${AppConstants.CONFIG_THEME_JSON.COLOR_SUCCESS_400}`); 
  bodyStyles.setProperty('--color-success-300', `${AppConstants.CONFIG_THEME_JSON.COLOR_SUCCESS_300}`); 
  bodyStyles.setProperty('--color-success-500', `${AppConstants.CONFIG_THEME_JSON.COLOR_SUCCESS_500}`); 
  bodyStyles.setProperty('--color-danger-400', `${AppConstants.CONFIG_THEME_JSON.COLOR_DANGER_400}`); 
  bodyStyles.setProperty('--color-danger-300', `${AppConstants.CONFIG_THEME_JSON.COLOR_DANGER_300}`); 
  bodyStyles.setProperty('--color-danger-500', `${AppConstants.CONFIG_THEME_JSON.COLOR_DANGER_500}`); 
  bodyStyles.setProperty('--color-on-primary', `${AppConstants.CONFIG_THEME_JSON.COLOR_ON_PRIMARY}`); 
  bodyStyles.setProperty('--color-green-300', `${AppConstants.CONFIG_THEME_JSON.COLOR_GREEN_300}`); 
  bodyStyles.setProperty('--color-green-400', `${AppConstants.CONFIG_THEME_JSON.COLOR_GREEN_400}`); 
  bodyStyles.setProperty('--color-green-500', `${AppConstants.CONFIG_THEME_JSON.COLOR_GREEN_500}`); 
  bodyStyles.setProperty('--color-on-green', `${AppConstants.CONFIG_THEME_JSON.COLOR_ON_GREEN}`); 
  bodyStyles.setProperty('--color-intermediate-400', `${AppConstants.CONFIG_THEME_JSON.COLOR_INTERMEDIATE_400}`);
  bodyStyles.setProperty('--color-intermediate-300', `${AppConstants.CONFIG_THEME_JSON.COLOR_INTERMEDIATE_300}`);
  bodyStyles.setProperty('--color-intermediate-500', `${AppConstants.CONFIG_THEME_JSON.COLOR_INTERMEDIATE_500}`);
  bodyStyles.setProperty('--main-font', `${AppConstants.CONFIG_THEME_JSON.MAIN_FONT}`); 
  bodyStyles.setProperty('--button-border-radius', `${AppConstants.CONFIG_THEME_JSON.BUTTON_BORDER_RADIUS}`); 
  bodyStyles.setProperty('--button-filled-border-width', `${AppConstants.CONFIG_THEME_JSON.BUTTON_FILLED_BORDER_WIDTH}`); 
  bodyStyles.setProperty('--button-regular-filled-padding', `${AppConstants.CONFIG_THEME_JSON.BUTTON_REGULAR_FILLED_PADDING}`);
  bodyStyles.setProperty('--button-regular-outline-padding', `${AppConstants.CONFIG_THEME_JSON.BUTTON_REGULAR_OUTLINE_PADDING}`);
  bodyStyles.setProperty('--button-small-filled-padding', `${AppConstants.CONFIG_THEME_JSON.BUTTON_SMALL_FILLED_PADDING}`); 
  bodyStyles.setProperty('--button-small-outline-padding', `${AppConstants.CONFIG_THEME_JSON.BUTTON_SMALL_OUTLINE_PADDING}`);
  bodyStyles.setProperty('--text-headline-font-weight', `${AppConstants.CONFIG_THEME_JSON.TEXT_HEADLINE_FONT_WEIGHT}`); 
  bodyStyles.setProperty('--button-secondary-outlined-color-border-focused', `${AppConstants.CONFIG_THEME_JSON.BUTTON_SECONDARY_OUTLINED_COLOR_BORDER_FOCUSED}`); 
  bodyStyles.setProperty('--button-tertiary-outlined-color-hovered', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_OUTLINED_COLOR_HOVERED}`); 
  bodyStyles.setProperty('--button-tertiary-outlined-color-background-hovered', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_OUTLINED_COLOR_BACKGROUND_HOVERED}`); 
  bodyStyles.setProperty('--button-tertiary-outlined-color-border-hovered', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_OUTLINED_COLOR_BORDER_HOVERED}`); 
  bodyStyles.setProperty('--button-tertiary-outlined-color-focused', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_OUTLINED_COLOR_FOCUSED}`); 
  bodyStyles.setProperty('--button-tertiary-outlined-color-background-focused', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_OUTLINED_COLOR_BACKGROUND_FOCUSED}`); 
  bodyStyles.setProperty('--button-tertiary-outlined-color-border-focused', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_OUTLINED_COLOR_BORDER_FOCUSED}`); 
  bodyStyles.setProperty('--button-tertiary-outlined-color-pressed', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_OUTLINED_COLOR_PRESSED}`); 
  bodyStyles.setProperty('--button-tertiary-outlined-color-background-pressed', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_OUTLINED_COLOR_BACKGROUND_PRESSED}`); 
  bodyStyles.setProperty('--button-tertiary-outlined-color-border-pressed', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_OUTLINED_COLOR_BORDER_PRESSED}`); 
  bodyStyles.setProperty('--button-secondary-filled-color-hovered', `${AppConstants.CONFIG_THEME_JSON.BUTTON_SECONDARY_FILLED_COLOR_HOVERED}`); 
  bodyStyles.setProperty('--button-secondary-filled-color-border-focused', `${AppConstants.CONFIG_THEME_JSON.BUTTON_SECONDARY_FILLED_COLOR_BORDER_FOCUSED}`); 
  bodyStyles.setProperty('--button-secondary-filled-box-shadow-focused', `${AppConstants.CONFIG_THEME_JSON.BUTTON_SECONDARY_FILLED_BOX_SHADOW_FOCUSED}`); 
  bodyStyles.setProperty('--button-tertiary-filled-color-hovered', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_FILLED_COLOR_HOVERED}`);
  bodyStyles.setProperty('--button-tertiary-filled-color-focused', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_FILLED_COLOR_FOCUSED}`);
  bodyStyles.setProperty('--button-tertiary-filled-color-pressed', `${AppConstants.CONFIG_THEME_JSON.BUTTON_TERTIARY_FILLED_COLOR_PRESSED}`);
  bodyStyles.setProperty('--page-header-background-color', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_BACKGROUND_COLOR}`);
  bodyStyles.setProperty('--button-intermediate-filled-color', `${AppConstants.CONFIG_THEME_JSON.BUTTON_INTERMEDIATE_FILLED_COLOR}`);
  bodyStyles.setProperty('--button-intermediate-filled-border-color', `${AppConstants.CONFIG_THEME_JSON.BUTTON_INTERMEDIATE_FILLED_BORDER_COLOR}`);
  bodyStyles.setProperty('--button-intermediate-filled-box-shadow-focused', `${AppConstants.CONFIG_THEME_JSON.BUTTON_INTERMEDIATE_FILLED_BOX_SHADOW_FOCUSED}`);
  bodyStyles.setProperty('--button-intermediate-filled-color-hovered', `${AppConstants.CONFIG_THEME_JSON.BUTTON_INTERMEDIATE_FILLED_COLOR_HOVERED}`);
  bodyStyles.setProperty('--button-intermediate-filled-color-focused', `${AppConstants.CONFIG_THEME_JSON.BUTTON_INTERMEDIATE_FILLED_COLOR_FOCUSED}`);
  bodyStyles.setProperty('--button-intermediate-filled-color-pressed', `${AppConstants.CONFIG_THEME_JSON.BUTTON_INTERMEDIATE_FILLED_COLOR_PRESSED}`);
  bodyStyles.setProperty('--page-header-text-color', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_TEXT_COLOR}`); 
  bodyStyles.setProperty('--page-header-menu-down-arrow-color', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_MENU_DOWN_ARROW_COLOR}`); 
  bodyStyles.setProperty('--page-header-add-new-item-background-color', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_ADD_NEW_ITEM_BACKGROUND_COLOR}`); 
  bodyStyles.setProperty('--page-header-add-new-item-text-color', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_ADD_NEW_ITEM_TEXT_COLOR}`); 
  bodyStyles.setProperty('--page-header-mobile-menu-toggle-button-color', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_MOBILE_MENU_TOGGLE_BUTTON_COLOR}`); 
  bodyStyles.setProperty('--page-header-mobile-menu-curtain-background-color', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_MOBILE_MENU_CURTAIN_BACKGROUND_COLOR}`); 
  bodyStyles.setProperty('--page-header-mobile-menu-curtain-background-opacity', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_MOBILE_MENU_CURTAIN_BACKGROUND_OPACITY}`); 
  bodyStyles.setProperty('--page-header-mobile-menu-curtain-text-color', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_MOBILE_MENU_CURTAIN_TEXT_COLOR}`); 
  bodyStyles.setProperty('--page-header-mobile-menu-curtain-text-color-item-selected', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_MOBILE_MENU_CURTAIN_TEXT_COLOR_ITEM_SELECTED}`); 
  bodyStyles.setProperty('--paper-primary-background-color', `${AppConstants.CONFIG_THEME_JSON.PAPER_PRIMARY_BACKGROUND_COLOR}`); 
  bodyStyles.setProperty('--paper-primary-text-color', `${AppConstants.CONFIG_THEME_JSON.PAPER_PRIMARY_TEXT_COLOR}`); 
  bodyStyles.setProperty('--paper-border-radius', `${AppConstants.CONFIG_THEME_JSON.PAPER_BORDER_RADIUS}`); 
  bodyStyles.setProperty('--paper-border-inner-radius', `${AppConstants.CONFIG_THEME_JSON.PAPER_BORDER_INNER_RADIUS}`); 
  bodyStyles.setProperty('--header-divider-underline-color', `${AppConstants.CONFIG_THEME_JSON.HEADER_DIVIDER_UNDERLINE_COLOR}`); 
  bodyStyles.setProperty('--input-focused-color', `${AppConstants.CONFIG_THEME_JSON.INPUT_FOCUSED_COLOR}`); 
  bodyStyles.setProperty('--select-focused-color', `${AppConstants.CONFIG_THEME_JSON.SELECT_FOCUSED_COLOR}`); 
  bodyStyles.setProperty('--select-with-search-focused-color', `${AppConstants.CONFIG_THEME_JSON.SELECT_WITH_SEARCH_FOCUSED_COLOR}`); 
  bodyStyles.setProperty('--pet-claim-and-recovery-history-download-icon-active', `${AppConstants.CONFIG_THEME_JSON.PET_CLAIM_AND_RECOVERY_HISTORY_DOWNLOAD_ICON_ACTIVE}`); 
  bodyStyles.setProperty('--option-border-radius', `${AppConstants.CONFIG_THEME_JSON.OPTION_BORDER_RADIUS}`); 
  bodyStyles.setProperty('--option-active-border-color', `${AppConstants.CONFIG_THEME_JSON.OPTION_ACTIVE_BORDER_COLOR}`);
  bodyStyles.setProperty('--option-active-check-mark-color', `${AppConstants.CONFIG_THEME_JSON.OPTION_ACTIVE_CHECK_MARK_COLOR}`);
  bodyStyles.setProperty('--option-active-background-color', `${AppConstants.CONFIG_THEME_JSON.OPTION_ACTIVE_BACKGROUND_COLOR}`);
  bodyStyles.setProperty('--option-checkbox-border-radius', `${AppConstants.CONFIG_THEME_JSON.OPTION_CHECKBOX_BORDER_RADIUS}`);
  bodyStyles.setProperty('--option-checkbox-active-border-color', `${AppConstants.CONFIG_THEME_JSON.OPTION_CHECKBOX_ACTIVE_BORDER_COLOR}`);
  bodyStyles.setProperty('--option-checkbox-active-check-mark-color', `${AppConstants.CONFIG_THEME_JSON.OPTION_CHECKBOX_ACTIVE_CHECK_MARK_COLOR}`);
  bodyStyles.setProperty('--option-checkbox-active-background-color', `${AppConstants.CONFIG_THEME_JSON.OPTION_CHECKBOX_ACTIVE_BACKGROUND_COLOR}`); 
  bodyStyles.setProperty('--go-paperless-card-color', `${AppConstants.CONFIG_THEME_JSON.GO_PAPERLESS_CARD_COLOR}`); 
  bodyStyles.setProperty('--go-paperless-card-header-color', `${AppConstants.CONFIG_THEME_JSON.GO_PAPERLESS_CARD_HEADER_COLOR}`);
  bodyStyles.setProperty('--text-link-color-hover', `${AppConstants.CONFIG_THEME_JSON.TEXT_LINK_COLOR_HOVER}`);
  bodyStyles.setProperty('--color-dd-link', `${AppConstants.CONFIG_THEME_JSON.COLOR_DD_LINK}`);
  bodyStyles.setProperty('--color-dd-link-hover', `${AppConstants.CONFIG_THEME_JSON.COLOR_DD_LINK_HOVER}`);
  bodyStyles.setProperty('--user-profile-card-title-color', `${AppConstants.CONFIG_THEME_JSON.USER_PROFILE_CARD_TITLE_COLOR}`);
  bodyStyles.setProperty('--pet-info-card-title-color', `${AppConstants.CONFIG_THEME_JSON.PET_INFO_CARD_TITLE_COLOR}`);
  bodyStyles.setProperty('--page-header-box-shadow', `${AppConstants.CONFIG_THEME_JSON.PAGE_HEADER_BOX_SHADOW}`);
  bodyStyles.setProperty('--shopping-cart-headline-background-color', `${AppConstants.CONFIG_THEME_JSON.SHOPPING_CART_HEADER_BACKGROUND_COLOR}`);
}
function loadFonts() {
  var newStyle = document.createElement("style");
  var font = `${AppConstants.CONFIG_THEME_JSON.FONT_FAMILY}`;
  if(font.includes(',')){
    font = font.split(',')[0];
  }
  newStyle.appendChild(
    document.createTextNode(
      "\
  @font-face {\
      font-family: " +
        font +
        ";\
      src: url('" +
        "../../fonts/" +
        font +
        ".otf" +
        "');\
      font-weight: 300;\
      font-style: normal;\
  }\
  "
    )
  );
  document.head.appendChild(newStyle);
  var bodyStyles = document.body.style;
  bodyStyles.setProperty(
    "--main-font",
    `${AppConstants.CONFIG_THEME_JSON.FONT_FAMILY}`
  );
}

function loadFavicon() {
  const favicon = document.getElementById("favicon");

  favicon.href =
    "/favicons/favicon-" + AppConstants.CONFIG_JSON.Program + ".ico";
}

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(clearUserDataAfterLogout()),
  };
};
export default connect(null, mapDispatchToProps)(withRouter(App));
